import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'modules/AsyncComponent.js';
import PrivateRoute from './PrivateRoute.js';
import LearningUserRoutes from './LearningUserRoutes.js';

import fr from 'lang/fr.js';

const AsyncLogin = asyncComponent(() => import('pages/login'));
const AsyncNoMatch = asyncComponent(() => import('pages/404'));
const AsyncHome = asyncComponent(() => import('pages/home'));
const AsyncCourses = asyncComponent(() => import('pages/courses'));
const AsyncLearningIndex = asyncComponent(() => import('pages/learning/index'));
const AsyncLearningShow = asyncComponent(() => import('pages/learning/show'));
const AsyncHomeworks = asyncComponent(() => import('pages/homeworks'));
// const AsyncReaderPdf = asyncComponent(() => import('pages/readerPdf/index'));
const AsyncQuestionsGroups = asyncComponent(() => import('pages/questionsGroups'));
const AsyncProfile = asyncComponent(() => import('pages/profile'));
const AsyncVideos = asyncComponent(() => import('pages/videos'));
const AsyncAgenda = asyncComponent(() => import('pages/agenda'));
const AsyncFaq = asyncComponent(() => import('pages/faq'));
const AsyncInternships = asyncComponent(() => import('pages/internships'));
const AsyncJobOffers = asyncComponent(() => import('pages/jobOffers'));
const AsyncJobOffersDetails = asyncComponent(() => import('pages/jobOffersDetails'));
const AsyncRanking = asyncComponent(() => import('pages/ranking'));
const AsyncChat = asyncComponent(() => import('pages/chat'));
const AsyncPositioning = asyncComponent(() => import('pages/positioning/positioning'));
const AsyncSchoolReport = asyncComponent(() => import('pages/schoolReport/index'));
const AsyncHomeworkPresentation = asyncComponent(() => import('pages/HomeworkPresentation/index'));
const AsyncHomeworkFreeQuestion = asyncComponent(() => import('pages/HomeworkFreeQuestion/index'));


const AsyncSuspendedAccount = asyncComponent(() => import('pages/suspendedAccount'));

const AsyncStyleguide = asyncComponent(() => import('pages/styleguide'));
const AsyncReport = asyncComponent(() => import('pages/report'));
const AsyncBadges = asyncComponent(() => import('pages/badges'));
const AsyncChallenges = asyncComponent(() => import('pages/challenges'));

const AsyncPaymentSuccess = asyncComponent(() => import('pages/payment/success'));
const AsyncPaymentFailure = asyncComponent(() => import('pages/payment/failure'));

const Router = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={AsyncHome} />

      <PrivateRoute exact path={ `/${ fr.route.courses }` } component={AsyncCourses} />
      <PrivateRoute exact path={ `/${ fr.route.learning }/:courseId` } component={AsyncLearningIndex} />
      <PrivateRoute exact path={ `/${ fr.route.learning }/:courseId/${ fr.route.chapter }/:chapterId` } component={AsyncLearningShow} />
      <PrivateRoute exact path={ `/${ fr.route.learning }/:courseId/${ fr.route.chapter }/:chapterId/${ fr.route.videos }` } component={AsyncVideos} />
      <PrivateRoute exact path={ `/${ fr.route.learning }/:courseId/${ fr.route.chapter }/:chapterId/${ fr.route.subchapter }/:subchapterId/${ fr.route.question }` } component={AsyncQuestionsGroups} />
      <PrivateRoute exact path={ `/${ fr.route.evaluate }/:courseId` } component={AsyncHomeworks} />
      {/* <PrivateRoute exact path={ '/flipbook' } component={AsyncReaderPdf} /> */}
      <PrivateRoute exact path={ `/${ fr.route.evaluate }/:courseId/${ fr.route.homework }/:homeworkId` } component={AsyncQuestionsGroups} />
      <PrivateRoute exact path={ `/${ fr.route.evaluate }/:courseId/${ fr.route.homeworkCorrection }/:homeworkCorrectionId` } component={AsyncQuestionsGroups} />
      <PrivateRoute exact path={ `/${ fr.route.revise }/:courseId` } component={AsyncQuestionsGroups} />
      <PrivateRoute exact path={ `/${ fr.route.challenge }/:challengeId` } component={AsyncQuestionsGroups} />
      <Route exact path={ '/questionPreview' } component={AsyncQuestionsGroups} />

      <PrivateRoute exact path={ `/${ fr.route.suspendedAccount }` } component={AsyncSuspendedAccount} />
      <PrivateRoute exact path={ `/${ fr.route.position }` } component={AsyncPositioning} />
      <PrivateRoute path={ `/${ fr.route.agenda }` } component={AsyncAgenda} />
      <LearningUserRoutes path={ `/${ fr.route.profile }` } component={AsyncProfile} />
      <PrivateRoute path={ `/${ fr.route.internships }` } component={AsyncInternships} />
      <LearningUserRoutes path={ `/${ fr.route.jobOffers }` } component={AsyncJobOffers} exact />
      <PrivateRoute path={ `/${ fr.route.jobOffers }/:id` } component={AsyncJobOffersDetails} />
      <PrivateRoute path={ `/${ fr.route.ranking }` } component={AsyncRanking} />
      <PrivateRoute path={ `/${ fr.route.report }` } component={AsyncReport} />
      <PrivateRoute path={ `/${ fr.route.badges }` } component={AsyncBadges} />
      <PrivateRoute path={ `/${ fr.route.challenges }` } component={AsyncChallenges} />
      <LearningUserRoutes path={ `/${ fr.route.chat }` } component={AsyncChat} />
      <PrivateRoute path={ `/${ fr.route.schoolReport }` } component={AsyncSchoolReport} />
      <PrivateRoute path={ `/${ fr.route.evaluate }/:courseId/${ fr.route.homework }/:homeworkId/${fr.route.presentation}` } component={AsyncHomeworkPresentation} />
      <PrivateRoute path={ `/${ fr.route.evaluate }/:courseId/${ fr.route.homework }/:homeworkId/${'editor'}` } component={AsyncHomeworkFreeQuestion} />
      <Route path={ `/${ fr.route.payment_success }` } component={AsyncPaymentSuccess} />
      <Route path={ `/${ fr.route.payment_failure }` } component={AsyncPaymentFailure} />
      
      <PrivateRoute path={ `/${ fr.route.faq }` } component={AsyncFaq} />
      
      <PrivateRoute exact path="/styleguide" component={AsyncStyleguide} />

      <Route path="/login" component={AsyncLogin} />
      <Route component={AsyncNoMatch} />
    </Switch>
  );
};

export default Router;
