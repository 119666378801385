import React from 'react';
import PropTypes from 'prop-types';
import EnhancedLink from 'components/atoms/enhancedLink';

import fr from 'lang/fr.js';

import LazyImage from 'components/atoms/lazyImage';
import imgUrl from 'utils/imgUrl.js';

import Notifications from './Notifications';
import Menu from './Menu';

import easySchoolLogo from '../../../assets/images/cja-learning-logo.png';

import './index.css';

const Header = ({
  pathname,
  me,
  isAuthenticated,
  isLoading,
  isProspect,
  isFreeAccount,
  onToggleMenuClick,
  openMenu,
  onToggleAccountAccordionClick,
  openAccountAccordion,
  isEasySchoolUser
}) =>
  <React.Fragment>
    {
      isProspect &&
        <div className="bg-teal-dark pgv-0_75">
          <div className="row">
            <div className="column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center">
              <span>Certaines fonctionnalités sont restreintes car vous êtes connecté avec un compte d’essai.</span>
            </div>
          </div>
        </div>
    }
    {
      isFreeAccount &&
      <div className="bg-teal-dark pgv-0_75">
        <div className="row">
          <div className="column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center">
            <span>Certaines fonctionnalités sont restreintes car vous êtes connecté avec un compte gratuit.</span>
          </div>
        </div>
      </div>
    }
    <header className="size-100p bg-purple pgv-0_5">
      <div className="row sm-align-spacebetween-center md-align-center-center">
        <div className="sm-mgr-auto md-mgr-0 column-4 no-width text-center">
          <EnhancedLink
            className="text-white h-text-gray-light flex align-center-center"
            activeClassName="text-primary-dark"
            isNavLink={ true }
            to="/cours"
            exact
          >
            <img src={easySchoolLogo} width={150} height={50} alt='EasySchool' />
          </EnhancedLink>
        </div>
      </div>
    </header>
  </React.Fragment>;


Header.propTypes = {
  me: PropTypes.object,
  isLoading: PropTypes.bool,
  isEasySchoolUser: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  isProspect: PropTypes.bool,
  isFreeAccount: PropTypes.bool,
  pathname: PropTypes.string,
  onToggleMenuClick: PropTypes.func,
  openMenu: PropTypes.bool,
  onToggleAccountAccordionClick: PropTypes.func,
  openAccountAccordion: PropTypes.bool,
};

export default Header;
