import 'cross-fetch/polyfill';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './store';
import App from './pages/app';
import { createToast } from './stores/toasts';
import registerServiceWorker from './registerServiceWorker';
import { addToGTM } from './components/molecules/gtm';

import 'sanitize.css/sanitize.css';
import './app.min.css';

history.listen((location, action) => {
  addToGTM({
    'event': 'VirtualPageview',
    'virtualPageURL': location.pathname
  });
});

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);

registerServiceWorker(store, createToast);
