import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import fr from 'lang/fr.js';

const PrivateRoute = ({ component: Component, props, ...rest }) => (
  <Route
    {...rest}
    render={p =>       
      p.location.isAuthenticated ? (

        p.location.isActive ||
        (!p.location.isActive && p.location.paymentBanner && p.location.pathname === `/${fr.route.profile}/${fr.route.profile_payment}`) ||
        (!p.location.isActive && !p.location.paymentBanner && p.location.pathname === `/${fr.route.suspendedAccount}`) ? (
            <Component {...p} { ...props } />
          ) : (
            p.location.paymentBanner ? (
              <Redirect
                to={{
                  pathname: `/${fr.route.profile}/${fr.route.profile_payment}`,
                  state: { from: p.location }
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: `/${fr.route.suspendedAccount}`,
                  state: { from: p.location }
                }}
              />
            )
          )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: p.location }
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object
};

export default PrivateRoute;
