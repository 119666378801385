import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';

import svgSprite from '../../assets/svg/sprite.svg';

import GoogleTagManager from '../../components/molecules/gtm';

import Header from '../../components/molecules/header';
import LoadIndicator from '../../components/atoms/loadIndicator';
import AlertBox from '../../components/atoms/alertBox';
import Router from '../../modules/router';
import Toasts from '../../components/atoms/toasts';
import Idle from '../../components/atoms/idle';
import PositionnementBanner from '../../components/molecules/PositionnementBanner';
import AuditTimer from '../../components/atoms/auditTimer';
import Alertbanner from '../../components/molecules/Alertbanner';

const App = (props) => {

  const { isLoading, error, catchedError, toasts, pathname, me, isAuthenticated } = props;
  const [OpenBanner, SetOpenBanner ] = useState(false);
  return (
    <AuditTimer me={me} pathname={pathname} isAuthenticated={isAuthenticated}>
      <Idle>
        <GoogleTagManager />
        <main>
          <SVG src={svgSprite} wrapper={React.createFactory('div')} uniquifyIDs={false} />
          {me.alert && localStorage.getItem('alertbanner') !== me.alert.id.toString() && <Alertbanner />}
          {isAuthenticated && OpenBanner ? ( <PositionnementBanner/>) : '' }
          {isAuthenticated && <Header />}
          <LoadIndicator isLoading={isLoading} size={75} />
          {((error && pathname !== '/login') || catchedError) && (
            <AlertBox
              type="alert"
              className="text-center"
              message={error ? '' : 'Une erreur est survenue. Veuillez recharger la page.'}
            />
          )}
  
          {!catchedError && <Router />}
          <Toasts toasts={toasts} />
        </main>
      </Idle>
    </AuditTimer>
  );
};

App.propTypes = {
  toasts: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.any,
  catchedError: PropTypes.any,
  pathname: PropTypes.string,
  me: PropTypes.object,
  isAuthenticated: PropTypes.bool
};

export default App;
