import React from 'react';
import PropTypes from 'prop-types';

import { formatDistance } from 'date-fns';
import fr from 'date-fns/locale/fr';

const Notification = ({
  id,
  category,
  message,
  link,
  read,
  date,
  color,
  icon,
  markAsRead
}) => (
  <div className={`notification flex align-start-start pgv-0_5 mgl-1 pgr-1 bdb-1-gray-light ${!read ? 'unread' : ''}`}>
    <div className={`pg-0_25 bg-${color} text-white bd-radius mgr-0_5`}>
      <svg className="w-2 h-2"><use xlinkHref={`#icon-${icon}`} /></svg>
    </div>
    <p
      className="no-margin fz-13 lh-1_425 text-black"
      onClick={ e => { markAsRead(); } }
    >
      { message } <span className="text-gray-dark">• { formatDistance(new Date(date), new Date(), {locale: fr}) }</span>
    </p>
  </div>
);

Notification.propTypes = {
  id: PropTypes.number,
  category: PropTypes.number,
  message: PropTypes.string,
  link: PropTypes.string,
  read: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  color: PropTypes.string,
  icon: PropTypes.string,
  markAsRead: PropTypes.func,
};

export default Notification;
