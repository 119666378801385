import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_AGENDA_REQUESTED = 'agenda/GET_AGENDA_REQUESTED';
export const GET_AGENDA_SUCCESSFUL = 'agenda/GET_AGENDA_SUCCESSFUL';
export const GET_AGENDA_FAILED = 'agenda/GET_AGENDA_FAILED';

export const GET_CONFIGURATION_REQUESTED = 'agenda/GET_CONFIGURATION_REQUESTED';
export const GET_CONFIGURATION_SUCCESSFUL = 'agenda/GET_CONFIGURATION_SUCCESSFUL';
export const GET_CONFIGURATION_FAILED = 'agenda/GET_CONFIGURATION_FAILED';

export const UPDATE_CONFIGURATION_REQUESTED = 'agenda/UPDATE_CONFIGURATION_REQUESTED';
export const UPDATE_CONFIGURATION_SUCCESSFUL = 'agenda/UPDATE_CONFIGURATION_SUCCESSFUL';
export const UPDATE_CONFIGURATION_FAILED = 'agenda/UPDATE_CONFIGURATION_FAILED';

const initialState = {
  events: [],
  configuration: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENDA_SUCCESSFUL:
      return {
        ...state,
        events: action.events,
      };
    case GET_AGENDA_FAILED:
      return  {
        ...state
      };

    case GET_CONFIGURATION_SUCCESSFUL:
      return {
        ...state,
        configuration: action.configuration
      };
    case GET_CONFIGURATION_FAILED:
      return {
        ...state
      };


    case UPDATE_CONFIGURATION_SUCCESSFUL:
      return {
        ...state,
        isConfigurationSetted: action.isConfigurationSetted
      };

    case UPDATE_CONFIGURATION_FAILED:
      return {
        ...state,
        isConfigurationSetted: action.isConfigurationSetted
      };

    default:
      return state;
  }
};

export const getEvents = (date) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_AGENDA_REQUESTED });

    let endpoint = /^\d{4}$/.test(date) ? `/agenda/yearly/${date}` : `/agenda/monthly/${date}`;

    return Api(endpoint, { params: params })
      .then(res => {

        dispatch({
          type: GET_AGENDA_SUCCESSFUL,
          events: res.data
        });
        dispatch({ type: ISNT_LOADING });
        return res.data;
      })
      .catch(err => {
        dispatch({ type: GET_AGENDA_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const getConfiguration = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_CONFIGURATION_REQUESTED });

    return Api('/agenda/configuration', { params: params })
      .then(res => {
        dispatch({
          type: GET_CONFIGURATION_SUCCESSFUL,
          configuration: res.data
        });
        dispatch({ type: ISNT_LOADING });
        return res.data;
      })
      .catch(err => {
        dispatch({ type: GET_CONFIGURATION_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const setConfiguration = (data) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: UPDATE_CONFIGURATION_REQUESTED });

    return Api.put('/agenda/configuration', data, { params: params })
      .then(res => {
        dispatch({
          type: UPDATE_CONFIGURATION_SUCCESSFUL,
          isConfigurationSetted: true
        });
        dispatch({ type: ISNT_LOADING });
        return res.data;
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CONFIGURATION_FAILED,
          isConfigurationSetted: false
        });
        dispatch({
          type: SET_ERROR,
          value: err
        });
        dispatch({ type: ISNT_LOADING });
      });
  };

