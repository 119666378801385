import { withRouter } from 'react-router-dom';

import { compose, mapProps, withState, withHandlers } from 'recompose';

import { connect } from 'react-redux';

import Header from './Header';

const enhance = compose(
  withRouter,
  connect(
    state => ({
      me: state.me,
      isLoading: state.isLoading,
      isAuthenticated: state.auth.isAuthenticated
    })
  ),
  withState('openMenu', 'updateOpenMenu', false),
  withState('openAccountAccordion', 'updateOpenAccountAccordion', false),
  withHandlers(() => {
    return {
      onToggleMenuClick: ({ updateOpenMenu, updateOpenAccountAccordion }) => e => {
        updateOpenMenu(o => !o);
        updateOpenAccountAccordion(o => false);
      },
      onToggleAccountAccordionClick: ({ updateOpenAccountAccordion }) => e => updateOpenAccountAccordion(o => !o)
    };
  }),
  mapProps(props => ({
    me: props.me,
    isLoading: props.isLoading,
    isAuthenticated: props.isAuthenticated,
    isProspect: props.me && props.me.roles && props.me.roles.indexOf('ROLE_PROSPECT') > -1,
    isFreeAccount: props.me && props.me.roles && props.me.roles.indexOf('ROLE_FREE') > -1,
    isEasySchoolUser: props.me && props.me.roles && props.me.roles.indexOf('ROLE_EASYCHOOL') > -2,
    onToggleMenuClick: props.onToggleMenuClick,
    openMenu: props.openMenu,
    onToggleAccountAccordionClick: props.onToggleAccountAccordionClick,
    openAccountAccordion: props.openAccountAccordion
  }))
);

export default enhance(Header);