import Api, { addCoaching, params } from '../modules/Api.js';
import fr from '../lang/fr.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

import { getMe, GET_ME_SUCCESSFUL, GET_ME_FAILED } from './me';

export const LOGIN_SUCCESSFUL = 'auth/LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';

export const RESET_SUCCESSFUL = 'auth/RESET_SUCCESSFUL';

export const NEW_SUCCESSFUL = 'auth/NEW_SUCCESSFUL';

export const LOGOUT_REQUESTED = 'auth/LOGOUT_REQUESTED';

const initialState = {
  positioningState: undefined,
  isAuthenticated: false,
  message: undefined,
  isConnected: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: true,
        message: undefined,
        positioningState: true,
        isConnected: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        positioningState: false,
        message: undefined
      };
    case RESET_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message
      };
    case NEW_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.message
      };
    case LOGOUT_REQUESTED:
      return {
        ...state,
        isAuthenticated: false,
        positioningState: false,
        message: action.message
      };
    default:
      return state;
  }
};

export const submit = (data, section, coach) => {
  return dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });

    switch (section) {
      case 'login':
        Api.post('/login_check', data, { params: params })
          .then(res => {
            localStorage.setItem('token', res.data.token);

            coach && addCoaching(coach);

            getMe(res.data.token)
              .then(user => {
                dispatch({
                  type: GET_ME_SUCCESSFUL,
                  user: user
                });

                dispatch({ type: LOGIN_SUCCESSFUL });
                dispatch({ type: ISNT_LOADING });
              })
              .catch(err => {
                dispatch({
                  type: SET_ERROR,
                  value: err.response && err.response.status === 401 ? 'unauthorized' : 'error'
                });

                dispatch({ type: GET_ME_FAILED });
                dispatch({ type: LOGIN_FAILED });
                dispatch({ type: ISNT_LOADING });
              });
          })
          .catch(err => {
            dispatch({
              type: SET_ERROR,
              value: err.response && err.response.status === 401 ? 'unauthorized' : 'error'
            });
            dispatch({ type: ISNT_LOADING });
          });

        break;

      case 'reset':
        Api.post('/reset_password', data, { params: params }).then(res => {
          dispatch({
            type: RESET_SUCCESSFUL,
            message: fr.login.success.reset
          });
          dispatch({ type: ISNT_LOADING });
        });

        break;

      case 'new':
        Api.put('/new_password', data, { params: params })
          .then(res => {
            dispatch({
              type: NEW_SUCCESSFUL,
              message: fr.login.success.new
            });
            dispatch({ type: ISNT_LOADING });
          })
          .catch(err => {
            dispatch({
              type: SET_ERROR,
              value: err.response && err.response.status === 400 ? 'invalidToken' : 'error'
            });
            dispatch({ type: ISNT_LOADING });
          });

        break;

      default:
        break;
    }
  };
};

export const check_token = token => {
  return async dispatch => {
    dispatch({ type: IS_LOADING });
    try {
      await Api.get('/check_token/' + token);
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        value: 'invalidToken'
      });
    }
    dispatch({ type: ISNT_LOADING });
  };
};

export const forced_logout = dispatch => {
  dispatch({ type: SET_ERROR, value: 'notLoggedIn' });
  dispatch({ type: IS_LOADING });

  localStorage.removeItem('token');
  localStorage.removeItem('coach');

  dispatch({ type: LOGOUT_REQUESTED });
  dispatch({ type: ISNT_LOADING });
};

export const logout = () => dispatch => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  localStorage.removeItem('token');
  localStorage.removeItem('coach');
  // sessionStorage.clear('alreadyVisited');

  dispatch({ type: LOGOUT_REQUESTED });
  dispatch({ type: ISNT_LOADING });
};
