let fr = {
  route: {
    courses: 'cours',
    learning: 'apprendre',
    videos: 'videos',
    revise: 'reviser',
    evaluate: 'evaluer',
    homework: 'devoir',
    presentation: 'presentation',
    homeworkCorrection: 'correction-devoir',
    chapter: 'chapitre',
    subchapter: 'sous-chapitre',
    question: 'questions',
    faq: 'faq',
    agenda: 'agenda',
    internships: 'stages',
    jobOffers: 'profil-emploi',
    ranking: 'classement',
    challenges: 'defis',
    challenge: 'defi',
    agenda_week: 'hebdomadaire',
    agenda_year: 'annuel',
    profile: 'mon-profil',
    report: 'bulletin',
    badges: 'badges',
    chat: 'messagerie',
    profile_settings: 'paramètres',
    profile_ids: 'identifiants',
    profile_shippings: 'livraisons',
    profile_documents: 'documents',
    profile_examinations: 'examens',
    profile_payment: 'paiement',
    payment_success: 'paiement-succes',
    payment_failure: 'paiement-echec',
    suspendedAccount: 'compte-suspendu',
    position: 'positionnement',
    schoolReport: 'livret-scolaire'
    // test multi ligne set
    // line: 'This is a line. \n This is another line. \n Yet another line'
  },
  meta: {
    login: {
      title: 'Quiz Comme J\'aime',
      description:
                'Connexion à CEF learning, l\'espace élève en ligne du Centre Européen de Formation. Accédez à vos modules e-learning, à votre communauté et discutez avec vos professeurs.'
    }
  },
  login: {
    form: {
      username: {
        login: 'N° d\'élève',
        reset: 'N° d\'élève'
      },
      password: {
        login: 'Mot de passe',
        new: 'Définir un mot de passe'
      }
    },
    lostPassword: 'Mot de passe oublié ?',
    submit: {
      login: 'Valider',
      reset: 'Réinitialiser le mot de passe',
      new: 'Valider'
    },
    success: {
      new: 'Votre mot de passe a été mis à jour. Veuillez vous reconnecter.',
      reset:
                'Un lien de réinitialisation de mot de passe vous a été envoyé sur votre boîte mail.\n Vous n’avez pas reçu le mail ? Patientez quelques minutes et pensez à vérifier vos indésirables.'
    },
    error: {
      notLoggedIn: 'Veuillez vous reconnecter.',
      unauthorized:
                'Votre identifiant ou mot de passe est erroné.\n Rappel : pour votre première connexion à CEF Learning, vous devez cliquer sur "Mot de passe oublié ?"',
      error: 'Une erreur est survenue, veuillez essayer plus tard.',
      invalidToken: 'Votre lien de réinitialisation semble invalide. Veuillez recommencer la procédure.'
    },
    backToLogin: {
      reset: 'Revenir à la page de connexion',
      new: 'Revenir à la page de connexion'
    },
    installBanner: {
      ios: {
        chrome: 'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-chrome.html',
        firefox: 'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-firefox.html',
        safari: 'https://www.centre-europeen-formation.fr/document/tutoriaux/index-iphone-safari.html'
      },
      android: {
        firefox: 'https://www.centre-europeen-formation.fr/document/tutoriaux/index-android-firefox.html'
      }
    }
  },
  positionnement: {
    text: 'N\'oubliez pas de réaliser votre test de positionnement obligatoire pour bien démarrer votre formation ! Cela ne vous prendra que quelques minutes.',
    text2: 'N\'oubliez pas de réaliser votre test de fin de formation ! Vous pourrez ainsi mesurer votre progression suite à votre formation. Cela ne vous prendra que quelques minutes.',
    alreadyVisited: 'N\'oubliez pas de terminer votre test de positionnement ! Cela ne vous prendra que quelques minutes.',
    click_link: 'Cliquez ici pour commencer',
    not_realized: 'Vous n’avez pas réalisé le test de positionnement permettant d’évaluer vos connaissances dans  le  domaine  de  formation  que  vous  avez  choisi.  Cette  étape  est  obligatoire  pour évaluer votre progression entre le début et la fin de votre formation.Ce test ne nécessite aucun prérequis. Sa durée est d’environ 3 minutes.Un test équivalent vous sera également proposé une fois votre formation terminée.Vous devez valider aujourd’hui cette étape importante pour accéder de nouveau à votre plateforme'
  },
  profile: {
    form: {
      firstName: 'Prénom',
      lastName: 'Nom',
      maidenName: 'Nom de jeune fille',
      birthDate: 'Date de naissance',
      gender: 'Civilité',
      family_situation: 'Situation familiale',
      email: 'Adresse email',
      address: 'Adresse',
      address2: 'Appartement',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      phone: 'Téléphone',
      phone1: 'Téléphone 01',
      phone2: 'Téléphone 02',
      phone3: 'Téléphone 03',
      subscriptionDate: 'Date d\'inscription',
      lastDegreeSpeciality: 'Dernier diplôme obtenu'
    },
    changePassword: {
      title: 'Modifier votre mot de passe',
      message: {
        success: 'Votre mot de passe  bien été mis à jour.'
      }
    },
    changeInfos: {
      message: {
        success: 'La demande de modification de vos informations a bien été transmise.'
      }
    },
    changePicture: {
      message: {
        success: 'Votre image de profil a bien été mise à jour.'
      }
    }
  },
  jobOffers: {
    changeCV: {
      message: {
        success: 'Votre CV a bien été mise à jour.'
      }
    }
  },
  chat: {
    postTopic: {
      message: {
        success: 'Votre message a bien été envoyé.'
      }
    },
    blockTopic: {
      message: {
        success: 'La conversation a bien été bloquée.'
      }
    },
    unblockTopic: {
      message: {
        success: 'La conversation a bien été débloquée.'
      }
    },
    signalTopic: {
      message: {
        success: 'La conversation a bien été signalée.'
      }
    }
  },
  questionsGroups: {
    endPopin: {
      title: [
        'C\'est fini pour ce jeu',
        'Jeu terminé!',
        'Votre jeu est terminé!',
        'Ce quiz est terminé!',
        'C\'est la fin de ce jeu!'
      ],
      notation: [
        // Level 0
        [
          {
            subtitle: 'Ne vous découragez pas.',
            subtitle_secondary: 'Recommencez tout de suite pour progresser !'
          },
          {
            subtitle: 'Persévérez!',
            subtitle_secondary: 'Recommencez ce jeu immédiatement pour améliorer votre score!'
          },
          {
            subtitle: 'N\'allez pas trop vite!',
            subtitle_secondary: 'Refaites ce jeu tout de suite pour augmenter vos chances de réussite!'
          },
          {
            subtitle: 'Reprenez ce jeu maintenant.',
            subtitle_secondary: 'Vous augmenterez ainsi vos probabilités de réussite!'
          },
          {
            subtitle: 'Recommencez ce jeu tout de suite.',
            subtitle_secondary: 'Vos possibilités de gagner des étoiles seront plus grandes!'
          }
        ],
        // Level 1
        [
          {
            subtitle: 'Vous pouvez faire mieux !',
            subtitle_secondary: 'Battez le fer tant qu’il est chaud !'
          },
          {
            subtitle: 'Vous pouvez améliorer votre score.',
            subtitle_secondary: 'Recommencez ce jeu rapidement!'
          },
          {
            subtitle: 'Le secret de la réussite : la volonté.',
            subtitle_secondary: 'Montrez que vous pouvez réussir et recommencez ce jeu sans plus attendre!'
          },
          {
            subtitle: 'Ce score ne vous ressemble pas.',
            subtitle_secondary: 'Montrez que vous pouvez faire mieux, refaites ce jeu séance tenante!'
          },
          {
            subtitle: 'Vous avez la capacité de faire mieux.',
            subtitle_secondary: 'A vous de le démontrer. Refaites ce jeu sans délai!'
          }
        ],
        // Level 2
        [
          {
            subtitle: 'Et si vous recommenciez ?',
            subtitle_secondary: 'La perfection n’est pas impossible !'
          },
          {
            subtitle: 'Ne vous contentez pas de ce score.',
            subtitle_secondary: 'Allez plus loin, recommencez ce jeu illico!'
          },
          {
            subtitle: 'La demi-teinte n\'est pas pour vous.',
            subtitle_secondary: 'Faites preuve d\'éclat, augmentez votre score en refaisant ce jeu!'
          },
          {
            subtitle: 'Vous ne pouvez pas vous satisfaire de ce score.',
            subtitle_secondary: 'Recommencez ce jeu sans tarder!'
          },
          {
            subtitle: 'Rien n\'est impossible avec de l\'obstination.',
            subtitle_secondary: 'Recommencez ce jeu pour atteindre les sommets!'
          }
        ],
        // Level 3
        [
          {
            subtitle: 'Pas mal du tout !',
            subtitle_secondary: 'Saurez-vous faire mieux ?'
          },
          {
            subtitle: 'Votre score est tout à fait correct.',
            subtitle_secondary: 'Et vous pouvez encore l\'améliorer n\'en doutez pas!'
          },
          {
            subtitle: 'Bel effort.',
            subtitle_secondary: 'Obtenez au moins 4 étoiles lors de votre prochaine tentative!'
          },
          {
            subtitle: 'Votre performance tient la route.',
            subtitle_secondary: 'Recommencez et tentez d\'obtenir au moins 4 étoiles!'
          },
          {
            subtitle: 'Votre score est acceptable.',
            subtitle_secondary: 'A vous de prouver que vous pouvez faire mieux!'
          }
        ],
        // Level 4
        [
          {
            subtitle: 'Très bon travail !',
            subtitle_secondary: 'La perfection n\'est pas loin !'
          },
          {
            subtitle: 'Jolie performance!',
            subtitle_secondary: 'Pensez-vous être capable d\'obtenir 5 étoiles?'
          },
          {
            subtitle: 'C\'est presque parfait.',
            subtitle_secondary: 'Mais pas tout à fait! Faites encore un essai pour atteindre les 5 étoiles!'
          },
          {
            subtitle: 'Vous n\'êtes pas loin du sommet.',
            subtitle_secondary: 'Encore un pas pour atteindre un score parfait!'
          },
          {
            subtitle: 'C\'est presque un sans faute.',
            subtitle_secondary: 'Lancez-vous le défi d\'atteindre les 5 étoiles!'
          }
        ],
        // Level 5
        [
          {
            subtitle: 'Parfait !',
            subtitle_secondary: 'Passez à la suite!'
          },
          {
            subtitle: 'Vous êtes au top!',
            subtitle_secondary: 'Continuez dans la foulée!'
          },
          {
            subtitle: 'Rien à dire.',
            subtitle_secondary: 'C\'est excellent!'
          },
          {
            subtitle: 'La perfection existe.',
            subtitle_secondary: 'Vous venez de le prouver!'
          },
          {
            subtitle: 'Bravo!',
            subtitle_secondary: 'Vous êtes un(e) élève 5 étoiles!'
          }
        ]
      ]
    }
  },
  chapters: {
    endPopin: {
      title: [
        'C\'est fini pour cette partie',
        'Cette partie est terminée!',
        'Cette partie du chapitre est finie!',
        'Cette section du chapitre est terminée!',
        'Ce point du chapitre est terminé!'
      ],
      subtitle: [
        'Ne relâchez pas vos effort!\nPassez à la suite!',
        'Pour terminer le chapitre, passez à la suite!',
        'Poursuivez vos efforts!',
        'Restez concentré(e) et passez à l\'étape suivante.',
        'Ne relâchez pas votre attention.\nPassez à l\'étape suivante!'
      ]
    }
  },
  positioning: {
    endPopin: {
      title: ['Merci pour votre participation']
    }
  },
  reviewing: {
    endPopin: {
      title: [
        'C\'est fini pour cette révision !',
        'Révision terminée !',
        'Votre révision est terminée !',
        'Cette révision est terminée !',
        'C\'est la fin de cette révision !'
      ],
      notation: [
        // Level 0
        [
          {
            subtitle: 'Ne vous découragez pas.',
            subtitle_secondary: 'Recommencez tout de suite pour progresser !'
          },
          {
            subtitle: 'Persévérez !',
            subtitle_secondary: 'Révisez à nouveau ce cours pour améliorer votre score !'
          },
          {
            subtitle: 'N\'allez pas trop vite !',
            subtitle_secondary:
                            'Reprenez cette révision tout de suite pour augmenter vos chances de réussite !'
          },
          {
            subtitle: 'Reprenez cette révision maintenant.',
            subtitle_secondary: 'Vous augmenterez ainsi vos probabilités de réussite !'
          },
          {
            subtitle: 'Recommencez cette révision tout de suite.',
            subtitle_secondary: 'Vos possibilité de gagner des étoiles seront plus grandes !'
          }
        ],
        // Level 1
        [
          {
            subtitle: 'Vous pouvez faire mieux !',
            subtitle_secondary: 'Battez le fer tant qu’il est chaud !'
          },
          {
            subtitle: 'Vous pouvez améliorer votre score.',
            subtitle_secondary: 'Recommencez cette révision rapidement !'
          },
          {
            subtitle: 'Le secret de la réussite : la volonté.',
            subtitle_secondary:
                            'Montrez que vous pouvez réussir et recommencez cette révision sans plus attendre !'
          },
          {
            subtitle: 'Ce score ne vous ressemble pas.',
            subtitle_secondary:
                            'Montrez que vous pouvez faire mieux, refaites cette révision séance tenante !'
          },
          {
            subtitle: 'Vous avez la capacité de faire mieux.',
            subtitle_secondary: 'A vous de le démontrer. Refaites cette révision sans délai !'
          }
        ],
        // Level 2
        [
          {
            subtitle: 'Et si vous recommenciez ? ',
            subtitle_secondary: 'La perfection n’est pas impossible !'
          },
          {
            subtitle: 'Ne vous contentez pas de ce score.',
            subtitle_secondary: 'Allez plus loin, recommencez cette révision illico!'
          },
          {
            subtitle: 'La demi-teinte n\'est pas pour vous.',
            subtitle_secondary: 'Faites preuve d\'éclat, augmentez votre score en refaisant cette révision !'
          },
          {
            subtitle: 'Vous ne pouvez pas vous satisfaire de ce score.',
            subtitle_secondary: 'Recommencez cette révision sans tarder !'
          },
          {
            subtitle: 'Rien n\'est impossible avec de l\'obstination.',
            subtitle_secondary: 'Recommencez cette révision pour atteindre les sommets !'
          }
        ],
        // Level 3
        [
          {
            subtitle: 'Pas mal du tout !',
            subtitle_secondary: 'Saurez-vous faire mieux ?'
          },
          {
            subtitle: 'Votre score est tout à fait correct. ',
            subtitle_secondary: 'Et vous pouvez encore l\'améliorer n\'en doutez pas !'
          },
          {
            subtitle: 'Bel effort.',
            subtitle_secondary: 'Obtenez au moins 4 étoiles lors de votre prochaine tentative !'
          },
          {
            subtitle: 'Votre performance tient la route.',
            subtitle_secondary: 'Recommencez et tentez d\'obtenir au moins 4 étoiles !'
          },
          {
            subtitle: 'Votre score est acceptable.',
            subtitle_secondary: 'A vous de prouver que vous pouvez faire mieux !'
          }
        ],
        // Level 4
        [
          {
            subtitle: 'Très bon travail !',
            subtitle_secondary: 'La perfection n’est pas loin !'
          },
          {
            subtitle: 'Jolie performance!',
            subtitle_secondary: 'Pensez-vous être capable d\'obtenir 5 étoiles ?'
          },
          {
            subtitle: 'C\'est presque parfait.',
            subtitle_secondary:
                            'Mais pas tout à fait! Faites encore un essai pour atteindre les 5 étoiles !'
          },
          {
            subtitle: 'Vous n\'êtes pas loin du sommet.',
            subtitle_secondary: 'Encore un pas pour atteindre un score parfait !'
          },
          {
            subtitle: 'C\'est presque un sans faute.',
            subtitle_secondary: 'Lancez-vous le défi d\'atteindre les 5 étoiles !'
          }
        ],
        // Level 5
        [
          {
            subtitle: 'Parfait !',
            subtitle_secondary: 'Passez à la suite !'
          },
          {
            subtitle: 'Vous êtes au top !',
            subtitle_secondary: 'Continuez dans la foulée !'
          },
          {
            subtitle: 'Rien à dire.',
            subtitle_secondary: 'C\'est excellent !'
          },
          {
            subtitle: 'La perfection existe.',
            subtitle_secondary: 'Vous venez de le prouver !'
          },
          {
            subtitle: 'Bravo !',
            subtitle_secondary: 'Vous êtes un / une élève 5 étoiles !'
          }
        ]
      ]
    }
  },
  homework: {
    homeworkIsSent: {
      message: {
        success: 'Votre devoir à bien été envoyé.',
        error:
                    'Une erreur est survenue lors de l\'envoi de votre devoir. Merci de retenter ultérieurement. Si le problème persiste, vous avez la possibilité de nous transmettre votre devoir par mail à &{sendingErrorEmail} en précisant votre numéro d\'élève et l\'intitulé du devoir.'
      }
    },
    endPopin: {
      autocorrect: {
        true: 'Devoir terminé',
        false: 'Devoir en attente de correction'
      }
    },
    sendWork: {
      send: 'Envoyer mon devoir',
      addFile: 'Ajouter un fichier',
      selectWork: 'Sélectionner mon fichier',
      acceptedFiles: 'Formats acceptés: ',
      sortInfo: 'Les fichiers seront envoyés en correction dans l\'ordre fourni.',
      sendFiles: 'Envoyer &{files} fichier(s)'
    }
  },
  challenge: {
    endPopin: {
      title: 'Défi terminé',
      winner: {
        true: {
          subtitle: 'Vous avez gagné !',
          subtitle_secondary: 'Continuez comme ça.'
        },
        false: {
          subtitle: 'Vous avez perdu.',
          subtitle_secondary: 'Vous ferez mieux la prochaine fois.'
        },
        null: {
          subtitle: '',
          subtitle_secondary: 'En attente de votre adversaire pour déterminer le vainqueur.'
        }
      }
    }
  },
  agenda: {
    configuration: {
      message: {
        success: 'La configuration de votre agenda a bien été prise en compte.',
        error:
                    'Votre agenda, tel que vous l\'avez personnalisé, ne vous permet pas d\'étudier dans les meilleures conditions. C\'est pourquoi nous vous conseillons d’allonger la durée de votre formation et/ou d’augmenter votre durée d’étude par jour.'
      },
      offer: {
        title: 'Ma formation',
        startDate: 'Début de ma formation',
        yearEnding: 'Année de passage de l\'examen',
        duration: 'Durée de la formation'
      },
      weeklySchedules: {
        title: 'Semaine type',
        workingOn: 'Je travaille le :',
        days: [
          'lundi pendant',
          'mardi pendant',
          'mercredi pendant',
          'jeudi pendant',
          'vendredi pendant',
          'samedi pendant',
          'dimanche pendant'
        ]
      },
      internship: {
        title: 'Mes stages',
        studyDuringInternship: 'J\'étudie pendant mes périodes de stages',
        start: 'Début',
        end: 'Fin',
        weeklyHours: 'Nombre d\'heures hebdomadaire',
        message: 'Nom du stage',
        add: 'Ajouter un stage'
      },
      unavailability: {
        title: 'Mes périodes d\'indisponibilités',
        start: 'Début de la période',
        end: 'Fin de la période',
        add: 'Ajouter une période d\'indisponibilité',
        message: 'Nom de la période'
      },
      adjustment: {
        title: 'Ajustement',
        reBuildFromToday: 'Planifier mes prochains cours à partir d’aujourd’hui'
      },
      submit: 'Demande de modifications',
      cancel: 'Annuler'
    }
  },
  internships: {
    title: 'Mes stages',
    documents: 'Mes documents',
    wordConvention: 'Convention au format .word',
    pdfConvention: 'Convention au format .pdf',
    guide: 'Guide',
    table: {
      headers: ['Structure', 'Durée', 'Date de réception de convention', 'Rapport'],
      hours: 'heures en',
      weeks: 'semaines',
      from: 'Du',
      to: 'au',
      certificate: 'Attestation'
    }
  },
  report: {
    title: 'Mon bulletin de notes',
    downloadReport: 'Télécharger mon bulletin',
    result: 'Résultat',
    congratsDescription: {
      1: 'Restez positif et étudiez chaque jour pour atteindre vos objectifs!',
      2: 'Votre moyenne est correcte. Poursuivez vos efforts!',
      3: 'Bon travail! Votre implication est récompensée!',
      4: 'C\'est bien! Votre persévérance fait plaisir!',
      5: 'Très bon travail ! Vous êtes au top!'
    },
    homeworks: {
      summary: 'Résumé des devoirs',
      detail: 'Détail des devoirs',
      myAverage: 'Ma moyenne',
      offerAverage: 'La moyenne de votre promo est',
      completed: 'Complétés',
      notCompleted: 'Il en reste',
      toCorrect: 'à corriger',
      toDo: 'à réaliser',
      table: {
        headers: ['Devoir', 'Coefficient', 'Reçu le', 'Correcteur', 'Corrigé le', 'Note', 'Note finale']
      }
    }
  },
  badges: {
    rank: ['Bronze', 'Argent', 'Or', 'Diamant'],
    description: {
      single: [
        'rendez-vous coach honoré',
        'cours terminé',
        'chapitre à 4 étoiles ou plus',
        'devoir noté 14 / 20 ou plus',
        'défi remporté'
      ],
      plural: [
        'rendez-vous coach honorés',
        'cours terminés',
        'chapitres à 4 étoiles ou plus',
        'devoirs notés 14 / 20 ou plus',
        'défis remportés'
      ]
    },
    threshold: {
      single: [
        'rendez-vous coach à honorer',
        'cours à terminer',
        'chapitre à 4 étoiles ou plus',
        'devoir noté 14 / 20 ou plus',
        'défi à remporter'
      ],
      plural: [
        'rendez-vous coach à honorer',
        'cours à terminer',
        'chapitres à 4 étoiles ou plus',
        'devoirs notés 14 / 20 ou plus',
        'défis à remporter'
      ]
    }
  },
  input: {
    required: 'Ce champs est requis.',
    email: 'Veuillez saisir une adresse email valide',
    tel: 'Veuillez saisir un numéro de téléphone valide',
    date: 'Veuillez saisir une date au format JJ/MM/AAAA',
    year: 'Veuillez saisir une année au format AAAA',
    number: 'Veuillez saisir un chiffre valide',
    studentNumber: 'Veuillez entrer votre numéro d\'élève : 1 lettre, 6 chiffres, 1 lettre. Exemple : F123456Z.'
  },
  error: {
    default: 'Erreur lors du chargement de la page',
    noChapter: 'Il semblerait que ce cours ne contienne aucun chapitre pour le moment !',
    noSubchapter: 'Il semblerait que ce chapitre ne contienne aucun sous-chapitre pour le moment !',
    noHomework: 'Chanceux, chanceuse, il semblerait qu\'aucun devoir ne soit à faire pour ce cours !'
  },
  toast: {
    newContent: {
      title: '🚀 Nouvelles fonctionnalités disponibles !',
      text: 'La page rechargera dans quelques secondes pour effectuer la mise à jour.'
    },
    offline: {
      title: '🙈 Pas de réseau ?',
      text: 'Votre application est maintenant disponible, même lorsque vous n\'avez plus de connexion !'
    },
    test: {
      title: 'Notification de test',
      text:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut magnam fuga obcaecati possimus ipsum odio sit architecto officiis incidunt dignissimos rem, autem, quis animi at eius iste ratione impedit sed?'
    }
  },
  examinations: {
    title: 'Examens',
    testAccount: 'Vous êtes sur un compte d’essai. Aucune information disponible sur les examens.',
    phone: 'Téléphone',
    website: 'Voir le site',
    list: {
      title: 'Mes résultats',
      year: 'Année',
      average: 'Moyenne',
      documents: 'Documents',
      status: 'Statut',
      certification: 'Attestation de réussite',
      success: 'Obtenu',
      failure: 'Ajourné',
      noCertification: 'Aucun document'
    },
    popin: {
      add: 'Ajouter un résultat',
      taken: 'Examen passé :',
      takingYear: 'Année de passage :',
      passed: 'Examen obtenu :',
      average: 'Moyenne obtenue :',
      yourAverage: 'Votre moyenne',
      averageRequired: 'Veuillez saisir votre moyenne',
      file: 'Copie de mon diplôme ou relevé de notes :',
      addFile: 'Ajouter un fichier',
      comment: 'Des remarques? Confiez-les nous :',
      yourComment: 'Votre réponse',
      doAdd: 'Valider',
      yes: 'Oui',
      no: 'Non',
      examAddedNotTaken: 'Votre réponse a été enregistrée.',
      examAddedNotPassed: 'Ne vous découragez pas !',
      examAddedPassed: 'Résultat ajouté !',
      closeWindow: 'Fermer la fenêtre',
      contact: 'Contactez-moi',
      notTaken:
                'Merci d’avoir renseigné ce formulaire. Toute l’équipe du Centre Européen de Formation reste à votre disposition pour vous accompagner dans votre formation.',
      notPassed:
                'Toute l’équipe du Centre Européen de Formation est à vos côtés pour vous accompagner. Vous avez besoin de faire le point avec votre coach? Cliquez sur « Contactez-moi ». Vous serez rappelé(e) dans les meilleurs délais.',
      doPassed:
                'Bravo! Toute l’équipe du Centre Européen de Formation vous félicite pour l’obtention de votre diplôme !',
      sendingError: 'Une erreur est survenue lors de l\'envoi du devoir. Veuillez réessayer plus tard.',
      contactError: 'Une erreur est survenue lors de la demande de contact. Veuillez réessayer plus tard.',
      contactSuccess: 'Votre demande de contact a bien été envoyée.',
      passedAverageError: 'L\'examen a été obtenu mais la moyenne est inférieure à 10.',
      notPassedAverageError: 'L\'examen n\'a pas été obtenu mais la moyenne est supérieure à 10.'
    }
  }
};

export default fr;
