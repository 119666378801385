import { compose, withState, withHandlers } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Alertbanner from './Alertbanner';

const enhance = compose(
  connect(
    state => ({
      me: state.me
    }),
    dispatch => bindActionCreators({
    }, dispatch)
  ),
  withState('showAlertBanner', 'updateShowAlertBanner', true),
  withHandlers(() => {
    
    return {
      closeAlertBanner: ({ updateShowAlertBanner, me }) => () => {
        updateShowAlertBanner(a => !a);
        localStorage.setItem('alertbanner', me.alert.id.toString());
      }
    };
  })
);

export default enhance(Alertbanner);
