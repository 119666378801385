import PropTypes from 'prop-types';
import env from '../env.js';

let imgUrl = (opts) => {
  // return [
  //   'https://source.unsplash.com/random/',
  //   [
  //     opts.width && opts.width,
  //     opts.height && opts.height
  //   ].filter(a => a).join('x')
  // ].join('');
  // return [
  //   'https://dummyimage.com/',
  //   [
  //     opts.width && opts.width,
  //     opts.height && opts.height
  //   ].filter(a => a).join('x'),
  //   '000',
  //   `fff&text=${opts.fileName ? opts.fileName : 'none'}`
  // ].join('/');
  return [
    env.apiUrl,
    opts.fileName ? `picture/${opts.fileName}` : 'picture/none',
    opts.width && `${opts.width}`,
    opts.height && `${opts.height}`
    // opts.q && `quality/${opts.q}`
  ].filter(a => a).join('/');
};
imgUrl.propType = {
  fileName: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  q: PropTypes.number
};

export default imgUrl;

// <img src={ imgUrl({ p: path, w: width, h: height, q: quality }) } alt="" />