import {
  compose,
  withState,
  withHandlers,
  lifecycle,
  mapProps
} from 'recompose';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { addToGTM } from 'components/molecules/gtm';

import { createToast } from 'stores/toasts';

import App from './App';

const enhance = compose(
  withRouter,
  connect(
    state => ({
      me: state.me,
      toasts: state.toasts,
      isLoading: state.isLoading,
      error: state.error,
      isAuthenticated: state.auth.isAuthenticated,
      positioningState: state.auth.positioningState
    }),
    dispatch => bindActionCreators({
      createToast
    }, dispatch)
  ),
  withState('catchedError', 'catchError', false),
  withState('lastLocation', 'updateLastLocation', null),
  withHandlers(() => ({
    onCatchError: ({ catchError }) => () => catchError(e => true)
  })),
  lifecycle({
    componentDidUpdate(nextProps){
      if(this.props.lastLocation !== nextProps.location.pathname){
        this.props.updateLastLocation(old => this.props.location.pathname);
      }
    },
    componentDidCatch(error, info){
      if(!this.props.catchedError){
        this.props.onCatchError();
        addToGTM({
          'errorMessage': error.toString(),
          'errorPreviousPage': this.props.lastLocation,
          'errorPage': window.location.href,
          'event': 'technicalError'
        });
      }
    }
  }),
  mapProps(props => {
    let seenToasts = JSON.parse(localStorage.getItem('seenToasts') || '[]');
    let unseenToasts = props.me.toasts
      ? props.me.toasts.filter(toast => !seenToasts.includes(toast.id) && !props.toasts.map(t => t.id).includes(toast.id))
      : [];

    unseenToasts.map(toast => props.createToast(toast));

    return {
      toasts: props.toasts,
      isLoading: props.isLoading,
      error: props.error,
      catchedError: props.catchedError,
      pathname: props.location.pathname,
      me: props.me,
      positioningState: props.positioningState,
      isAuthenticated: props.isAuthenticated,
    };
  })
);

export default enhance(App);
