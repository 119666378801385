let envs = {
  production: {
    easySchoolUrl: 'https://commejaime.fr/connexion',
    apiUrl: 'https://api.elearning.commejaime.fr',
    GTM: {
      code: ''
    },
    debug: false,
    payment: {
      salt1: '54s!oiudf',
      salt2: 'PRkx@r592',
      url: 'https://perfect-cef.fr/cgi/payment.php'
    },
    limitConnexionAmount: 10,
    TimeToSendData: 600,
    inactivityTime: 1000 * 3600,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    homework: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg', 'jpeg'],
      sendingErrorEmail: 'professeur@cenef.fr',
      sentMessageDuration: 1000 * 15 //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 9h à 17h',
      cenefPhone: '+33 (0)3 66 06 02 01'
    }
  },
  staging: {
    easySchoolUrl: 'https://rct-cja-cs.prep1.prepxy.fr/',
    apiUrl: 'https://re7.api.learning.xyn-it.fr',
    GTM: {
      code: ''
    },
    debug: true,
    payment: {
      salt1: '54s!oiudf',
      salt2: 'PRkx@r592',
      url: 'https://perfect.genius-cef.com/cgi/payment.php'
    },
    limitConnexionAmount: 10,
    TimeToSendData: 600,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    inactivityTime: 1000 * 3600 * 3,
    homework: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg', 'jpeg'],
      sendingErrorEmail: 'cestmoileprof@centre-europeen-formation.fr',
      sentMessageDuration: 1000 * 15 //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 9h à 17h',
      cenefPhone: '+33 (0)3 66 06 02 01'
    }
  },
  development: {
    easySchoolUrl: 'https://rct-cja-cs.prep1.prepxy.fr/',
    apiUrl: 'https://re7.api.learning.xyn-it.fr',
    //apiUrl: 'https://api.cenef.siliconsalad.com',
    //apiUrl: 'https://api.learning.commejaime.fr',
    GTM: {
      code: ''
    },
    debug: true,
    limitConnexionAmount: 10,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    TimeToSendData: 600,
    payment: {
      salt1: '54s!oiudf',
      salt2: 'PRkx@r592',
      url: 'https://perfect.genius-cef.com/cgi/payment.php'
    },
    inactivityTime: 1000 * 60 * 30, //30 minutes
    IdleUserTimeout: 500,
    TimeToSendTracking: 5000,
    homework: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg', 'jpeg'],
      sendingErrorEmail: 'cestmoileprof@centre-europeen-formation.fr',
      sentMessageDuration: 1000 * 15 //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 9h à 17h',
      cenefPhone: '+33 (0)3 66 06 02 01'
    }
  }
};

export default envs[process.env.REACT_APP_HOST_ENV || process.env.NODE_ENV];
