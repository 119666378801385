import React from 'react';
import PropTypes from 'prop-types';

import { compose, mapProps } from 'recompose';

import fr from '../../lang/fr.js';

const AlertBox = ({ message, className}) => (
  <div className={ className }>
    <p className="no-margin">{ message }</p>
  </div>
);

AlertBox.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
};

let enhance = compose(
  mapProps(props => ({
    message: props.message.split(/\n/).reduce((t, s, i) => i === 0 ? [...t, <React.Fragment key={ i.toString() }>{ s }</React.Fragment>] : [...t, <br key={ (i+20).toString() }/>, <React.Fragment key={ i.toString() }>{ s }</React.Fragment> ], []) || fr.error.default,
    className: [
      'pg-0_5',
      ...(props.className || '').split(' '),
      ...(props.type === 'alert' ? 'bg-alert-muted text-alert bd-1-alert' : '').split(' '),
      ...(props.type === 'success' ? 'bg-success-muted bd-1-success' : '').split(' '),
      ...(props.type === 'info' ? 'bg-info-light bd-1-info' : '').split(' ')
    ].join(' ')
  }))
);

export default enhance(AlertBox);