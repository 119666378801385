import Api, { params } from '../modules/Api.js';

import { SET_ERROR, RESET_ERROR } from './error';

export const GET_QUESTION_REQUESTED = 'questionsGroups/GET_QUESTION_REQUESTED';
export const POST_QUESTION_REQUESTED = 'questionsGroups/POST_QUESTION_REQUESTED';
export const CHANGE_ANSWER_REQUESTED = 'questionsGroups/CHANGE_ANSWER_REQUESTED';
export const GET_SCORING_REQUESTED = 'questionsGroups/GET_SCORING_REQUESTED';
export const RESET_QUESTIONS_REQUESTED = 'questionsGroups/RESET_QUESTIONS_REQUESTED';
export const RESET_REVIEWING_REQUESTED = 'questionsGroups/RESET_REVIEWING_REQUESTED';
export const RESET_HOMEWORK_REQUESTED = 'questionsGroups/RESET_HOMEWORK_REQUESTED';
export const GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED = 'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED';
export const GET_CHALLENGE_QUESTION_REQUESTED = 'questionsGroup/GET_CHALLENGE_QUESTION_REQUESTED';
export const GET_CHALLENGE_SCORING_REQUESTED = 'questionsGroup/GET_CHALLENGE_SCORING_REQUESTED';
export const POST_CHALLENGE_QUESTION_REQUESTED = 'questionsGroup/POST_CHALLENGE_QUESTION_REQUESTED';
export const RESET_CHALLENGE_REQUESTED = 'questionsGroup/RESET_CHALLENGE_REQUESTED';
export const GET_HOMEWORK_CORRECTION_REQUESTED = 'homeworks/GET_HOMEWORK_CORRECTION_REQUESTED';
export const GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED = 'questionsGroups/GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED';

export const GET_QUESTION_SUCCESSFUL = 'questionsGroups/GET_QUESTION_SUCCESSFUL';
export const GET_QUESTION_FAILED = 'questionsGroups/GET_QUESTION_FAILED';

export const POST_QUESTION_SUCCESSFUL = 'questionsGroups/POST_QUESTION_SUCCESSFUL';
export const POST_QUESTION_FAILED = 'questionsGroups/POST_QUESTION_FAILED';

export const CHANGE_ANSWER_SUCCESSFUL = 'questionsGroups/CHANGE_ANSWER_SUCCESSFUL';
export const CHANGE_ANSWER_FAILED = 'questionsGroups/CHANGE_ANSWER_FAILED';

export const GET_SCORING_SUCCESSFUL = 'questionsGroups/GET_SCORING_SUCCESSFUL';
export const GET_SCORING_FAILED = 'questionsGroups/GET_SCORING_FAILED';

export const RESET_QUESTIONS_SUCCESSFUL = 'questionsGroups/RESET_QUESTIONS_SUCCESSFUL';
export const RESET_QUESTIONS_FAILED = 'questionsGroups/RESET_QUESTIONS_FAILED';

export const RESET_REVIEWING_SUCCESSFUL = 'questionsGroups/RESET_REVIEWING_SUCCESSFUL';
export const RESET_REVIEWING_FAILED = 'questionsGroups/RESET_REVIEWING_FAILED';

export const RESET_HOMEWORK_SUCCESSFUL = 'questionsGroups/RESET_HOMEWORK_SUCCESSFUL';
export const RESET_HOMEWORK_FAILED = 'questionsGroups/RESET_HOMEWORK_FAILED';

export const GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL = 'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL';
export const GET_HOMEWORK_PREVNEXT_QUESTION_FAILED = 'questionsGroups/GET_HOMEWORK_PREVNEXT_QUESTION_FAILED';

export const RESET_QUESTION_SCORING_REQUESTED = 'questionsGroups/RESET_QUESTION_SCORING_REQUESTED';


export const GET_CHALLENGE_QUESTION_SUCCESSFUL = 'questionsGroup/GET_CHALLENGE_QUESTION_SUCCESSFUL';
export const GET_CHALLENGE_QUESTION_FAILED = 'questionsGroup/GET_CHALLENGE_QUESTION_FAILED';

export const POST_CHALLENGE_QUESTION_SUCCESSFUL = 'questionsGroup/POST_CHALLENGE_QUESTION_SUCCESSFUL';
export const POST_CHALLENGE_QUESTION_FAILED = 'questionsGroup/POST_CHALLENGE_QUESTION_FAILED';

export const GET_CHALLENGE_SCORING_SUCCESSFUL = 'questionsGroup/GET_CHALLENGE_SCORING_SUCCESSFUL';
export const GET_CHALLENGE_SCORING_FAILED = 'questionsGroup/GET_CHALLENGE_SCORING_FAILED';

export const RESET_CHALLENGE_SUCCESSFUL = 'questionsGroup/RESET_CHALLENGE_SUCCESSFUL';
export const RESET_CHALLENGE_FAILED = 'questionsGroup/RESET_CHALLENGE_FAILED';

export const GET_HOMEWORK_CORRECTION_SUCCESSFUL = 'questionsGroup/GET_HOMEWORK_CORRECTION_SUCCESSFUL';
export const GET_HOMEWORK_CORRECTION_FAILED = 'questionsGroup/GET_HOMEWORK_CORRECTION_FAILED';

export const GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL = 'questionsGroup/GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL';
export const GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED = 'questionsGroup/GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED';

const initialState = {
  question: {},
  answer: {},
  scoring: undefined,
  winner: undefined,
  challenger: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false
      };
    case GET_QUESTION_FAILED:
      return initialState;

    case GET_HOMEWORK_CORRECTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_HOMEWORK_CORRECTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: action.answer,
        isLoading: false
      };
    case GET_HOMEWORK_CORRECTION_FAILED:
      return initialState;

    case GET_HOMEWORK_PREVNEXT_CORRECTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_HOMEWORK_PREVNEXT_CORRECTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: action.answer,
        isLoading: false
      };
    case GET_HOMEWORK_PREVNEXT_CORRECTION_FAILED:
      return initialState;

    case GET_CHALLENGE_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_CHALLENGE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false
      };
    case GET_CHALLENGE_QUESTION_FAILED:
      return initialState;

    case GET_HOMEWORK_PREVNEXT_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_HOMEWORK_PREVNEXT_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false
      };
    case GET_HOMEWORK_PREVNEXT_QUESTION_FAILED:
      return initialState;


    case POST_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case POST_QUESTION_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false
      };
    case POST_QUESTION_FAILED:
      return initialState;

    case CHANGE_ANSWER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case CHANGE_ANSWER_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false
      };
    case CHANGE_ANSWER_FAILED:
      return initialState;

    case POST_CHALLENGE_QUESTION_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case POST_CHALLENGE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        answer: action.answer,
        isLoading: false
      };
    case POST_CHALLENGE_QUESTION_FAILED:
      return initialState;
    case GET_SCORING_SUCCESSFUL:
      return {
        ...state,
        scoring: action.scoring,
        scoringLastTest: action.scoringLastTest
      };
    case GET_SCORING_FAILED:
      return {
        ...state,
        scoring: undefined
      };

    case GET_CHALLENGE_SCORING_SUCCESSFUL:
      return {
        ...state,
        scoring: action.scoring,
        winner: action.winner,
        challenger: action.challenger
      };
    case GET_CHALLENGE_SCORING_FAILED:
      return {
        ...state,
        scoring: undefined
      };

    case RESET_QUESTIONS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case RESET_QUESTIONS_SUCCESSFUL:
      return initialState;
    case RESET_QUESTIONS_FAILED:
      return initialState;
    case RESET_QUESTION_SCORING_REQUESTED:
      return {
        ...state,
        question: {
          ...state.question,
          scoring: undefined
        }
      };

    case RESET_REVIEWING_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case RESET_REVIEWING_SUCCESSFUL:
      return initialState;
    case RESET_REVIEWING_FAILED:
      return initialState;

    case RESET_CHALLENGE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case RESET_CHALLENGE_SUCCESSFUL:
      return initialState;
    case RESET_CHALLENGE_FAILED:
      return initialState;

    case RESET_HOMEWORK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case RESET_HOMEWORK_SUCCESSFUL:
      return initialState;
    case RESET_HOMEWORK_FAILED:
      return initialState;
    default:
      return state;
  }
};

export const getPosQuestion = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_QUESTION_REQUESTED });

    return Api('positioning_tests/questions_groups', { params: params })
      .then(res => {
        let { data } = res;
        
        dispatch({
          type: GET_QUESTION_SUCCESSFUL,
          question: Array.isArray(data) ? {} : data
        });

        return data;
      })
      .catch(err => {
        dispatch({ type: GET_QUESTION_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
      });
  };

export const setQuestion = ({question}) =>
  dispatch => {
    dispatch({
      type: GET_QUESTION_SUCCESSFUL,
      question
    });
  };

export const answerQuestionPositioning = ({data}) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: POST_QUESTION_REQUESTED });

    let endpoint = '/positioning_tests/questions_groups';

    return Api.post(endpoint, data, { params: params })
      .then(res => {
        let { data } = res;
        
        dispatch({
          type: POST_QUESTION_SUCCESSFUL,
          answer: Array.isArray(data) ? {} : data
        });
      })
      .catch(err => {
        dispatch({ type: POST_QUESTION_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
      });
  };


export const getScoringPositioning = () =>
  async dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_SCORING_REQUESTED });


    let endpoint = '/positioning_tests/questions_groups/end';
    
    return Api(endpoint, { params: params })
      .then(res => {

        let { data } = res;

        let firstResultArray = false;
        let secondResultArray = false;
        let firstCumulativeScoring = 0;
        let firstCumulativeWeigh = 0;
        let lastCumulativeWeigh = 0;
        let lastCumulativeScoring = 0;
        
        for (let i = 0; i < data.length; i++ ) {
          firstResultArray = data[0].scoring;
          secondResultArray = data[1] ? data[1].scoring : null;
        }
        if (firstResultArray)
        {
          for (let i = 0; i < firstResultArray.length; i++) {
            firstCumulativeScoring = firstCumulativeScoring + firstResultArray[i].cumulativeScoring;
            firstCumulativeWeigh = firstCumulativeWeigh + firstResultArray[i].cumulativeWeigh;
          }
        }
        if (secondResultArray)
        {
          for (let i = 0; i < secondResultArray.length; i++) {
            lastCumulativeScoring = lastCumulativeScoring + secondResultArray[i].cumulativeScoring;
            lastCumulativeWeigh = lastCumulativeWeigh + secondResultArray[i].cumulativeWeigh;
          }
        }

        let scoring1 = firstCumulativeScoring || firstCumulativeWeigh > 0  ? {divisor: firstCumulativeWeigh, dividend: firstCumulativeScoring } : null;
        let scoringLastTest = lastCumulativeScoring  || lastCumulativeWeigh > 0 ? {divisor: lastCumulativeWeigh, dividend: lastCumulativeScoring } : null;
        
        dispatch({
          type: GET_SCORING_SUCCESSFUL,
          scoring: scoring1,
          scoringLastTest: scoringLastTest ? scoringLastTest : null
        });
        return res;
      })
      .catch(err => {
        dispatch({ type: GET_SCORING_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
      });
  };


