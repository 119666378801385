import React from 'react';
import PropTypes from 'prop-types';

import Notification from './Notification';

const Notifications = ({
  onContainerRef,
  onTriggerRef,
  notifications,
  unreadNotifications,
  toggleNotifications,
  shouldShowNotifications,
  shouldShowMoreNotifications,
  toggleMoreNotifications
}) =>
  <React.Fragment>
    <div ref={ onTriggerRef } className="h-bg-primary-dark anim-all round notifications__trigger w-5 h-5 flex align-center-center curp mgr-0_5" onClick={toggleNotifications}>
      <div className="relative">
        <svg className="w-3 h-3 text-white"><use xlinkHref="#icon-alert"/></svg>
        { unreadNotifications > 0 &&
          <span className="round bd-2-primary flex align-center-center bg-alert fz-12 fw-700 text-white lh-1">
            { unreadNotifications }
          </span>
        }
      </div>
      { shouldShowNotifications &&
        <div ref={ onContainerRef } className={`notifications__list ${shouldShowNotifications ? 'active': ''}`}>
          <h3 className="no-margin pg-1 bdb-1-gray-light">Notifications</h3>
          { notifications.map(notification =>
            <Notification key={notification.id} {...notification} toggleNotifications={toggleNotifications} />
          )}
          { notifications.length === 0 && (
            <div className="notification flex align-start-start pgv-0_5 mgl-1">
              <p className="no-margin fz-13 lh-1_425 text-black">Aucune notification</p>
            </div>
          )}
          { notifications.length > 4 && !shouldShowMoreNotifications &&
            <button className="button primary white big notifications__more-button" onClick={ toggleMoreNotifications }>
              Voir tout
            </button>
          }
        </div>
      }
    </div>
  </React.Fragment>
;

Notifications.propTypes = {
  onContainerRef: PropTypes.func,
  onTriggerRef: PropTypes.func,
  notifications: PropTypes.array,
  unreadNotifications: PropTypes.number,
  shouldShowNotifications: PropTypes.bool,
  toggleNotifications: PropTypes.func,
  shouldShowMoreNotifications: PropTypes.bool,
  toggleMoreNotifications: PropTypes.func
};

export default Notifications;
