export const ADD_TOAST = 'toast/ADD_TOAST';
export const REMOVE_TOAST = 'toast/REMOVE_TOAST';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return [
        ...state,
        {
          ...action.toast,
          id: action.toast.id || state.reduce((tot, t) => t.id >= tot ? t.id + 1 : tot, []) || 0
        }
      ];
    case REMOVE_TOAST:
      return [
        ...state.filter(t => t.id !== action.id)
      ];

    default:
      return state;
  }
};

export const createToast = (toast) => ({
  type: ADD_TOAST,
  toast: typeof toast === 'string' ? { type: toast } : toast
});

export const addToast = type =>
  dispatch => {
    dispatch(createToast(type));
  };

export const removeToast = (id, type) =>
  dispatch => {

    if(!type){
      let seenToasts = JSON.parse(localStorage.getItem('seenToasts') || '[]');
      seenToasts.push(id);
      localStorage.setItem('seenToasts', JSON.stringify(seenToasts));
    }
    dispatch({
      type: REMOVE_TOAST,
      id
    });
  };
