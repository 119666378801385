import { compose, withState, withHandlers } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getNotifications } from 'stores/notifications';

import Notifications from './Notifications';

const enhance = compose(
  connect(
    state => ({
      notifications: state.notifications
    }),
    dispatch => bindActionCreators({
      getNotifications
    }, dispatch)
  ),
  withState('shouldShowNotifications', 'updateNotificationsStatus', false),
  withState('shouldShowMoreNotifications', 'updateMoreNotificationsStatus', false),
  withHandlers(({ updateNotificationsStatus, updateMoreNotificationsStatus }) => {
    let container = null;
    let trigger = null;
    let handleClick = e => {
      let t = e.target;
      while(t !== document && t !== container && t !== trigger){
        if(t === document.body){
          updateNotificationsStatus(false);
          updateMoreNotificationsStatus(false);
        }
        t = t.parentNode;
      }
    };

    return {
      onContainerRef: () => el => (container = el),
      onTriggerRef: () => el => (trigger = el),
      toggleNotifications: ({ shouldShowNotifications, updateNotificationsStatus, updateMoreNotificationsStatus, getNotifications }) => () => {
        if(shouldShowNotifications){
          updateMoreNotificationsStatus(false);
          document.body.removeEventListener('click', handleClick);
        }
        else{
          getNotifications(5);
          document.body.addEventListener('click', handleClick);
        }
        updateNotificationsStatus(!shouldShowNotifications);
      },
      toggleMoreNotifications: ({ shouldShowMoreNotifications, updateMoreNotificationsStatus, getNotifications }) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        getNotifications();
        updateMoreNotificationsStatus(true);
      }
    };
  })
);

export default enhance(Notifications);
