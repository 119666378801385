export const RESET_SELECTED_COURSE = 'selected/RESET_SELECTED_COURSE';
export const SET_SELECTED_COURSE = 'selected/SET_SELECTED_COURSE';
export const RESET_SELECTED_CHAPTER = 'selected/RESET_SELECTED_CHAPTER';
export const SET_SELECTED_CHAPTER = 'selected/SET_SELECTED_CHAPTER';

const initialState = {
  course: {},
  chapter: {} 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_SELECTED_COURSE:
      return {
        ...state,
        course: initialState.course
      };
    case SET_SELECTED_COURSE:
      return {
        ...state,
        course: action.selectedCourse
      };
    case RESET_SELECTED_CHAPTER:
      return {
        ...state,
        chapter: initialState.chapter
      };
    case SET_SELECTED_CHAPTER:
      return {
        ...state,
        chapter: action.selectedChapter
      };
    default:
      return state;
  }
};

export const setSelectedChapter = selectedChapter =>
  dispatch => {
    dispatch({
      type: SET_SELECTED_CHAPTER,
      selectedChapter
    });
  };

export const resetSelectedChapter = selectedChapter =>
  dispatch => {
    dispatch({
      type: RESET_SELECTED_CHAPTER
    });
  };