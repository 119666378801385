import React, { useState, useEffect } from 'react';
import fr from 'lang/fr.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EnhancedLink from 'components/atoms/enhancedLink';

const PositionnementBanner = (props) => {

  // const [counter, setCounter] = useState(0);
  const [alreadyVisitedTest, SetAlreadyVisitedTest] = useState(false);
  const [whichTest, SetWhichTest] = useState();

  
  const  {me} = props;

  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  const replaceTextIfVisited = () => {
    if ((me.selectedOffer.firstPositioningTestStatus === 1 && me.selectedOffer.lastPositioningTestStatus !== 1) || me.selectedOffer.lastPositioningTestStatus === 1)
    {
      SetAlreadyVisitedTest(true);
      SetWhichTest(undefined);
    }
    else if (me.selectedOffer.firstPositioningTestStatus === 2 && me.selectedOffer.lastPositioningTestStatus === 0)
    {
      SetAlreadyVisitedTest(false);
    }
  };

  const replaceTextOnTest = () => {
    if (me.selectedOffer.firstPositioningTestStatus === 0 || me.selectedOffer.firstPositioningTestStatus === 1)
    {
      SetWhichTest(0);  
    }
    else if (me.selectedOffer.lastPositioningTestStatus === 0 || me.selectedOffer.lastPositioningTestStatus === 1)
    {
      SetWhichTest(1);
    }
  };

  useEffect(() => {
    replaceTextIfVisited();
    replaceTextOnTest();
  });

  // useEffect(() => {
  //   if (isConnected)
  //   {
  //     setCounter(prevValue => prevValue + 1);
  //   }
  // }, []);

  return (
    <header className={ `alertbanner bg-teal-dark pgv-0_75 ${!open && 'hide'}` }>
      <div className="row">
        <div className="column-12 fz-13 fw-600 text-white flex no-width text-center">

          {alreadyVisitedTest ? (
            <div>
              <span className="no-margin">{fr.positionnement.alreadyVisited}</span> 
              <br /> 
              <span className="text-center" ><EnhancedLink to={ `/${fr.route.position}` } onClick={onClose} className="text-white mgb-0" style={{textDecoration: 'underline'}}> Cliquez ici pour reprendre</EnhancedLink> </span> 
            </div>
          ) : whichTest === 0 ?
            <div> 
              <span >{fr.positionnement.text}</span>  
              <span className="text-center" ><EnhancedLink to={ `/${fr.route.position}` } onClick={onClose} className="text-white mgb-0" style={{textDecoration: 'underline'}}> Cliquez ici pour commencer</EnhancedLink> </span>
            </div> : whichTest === 1 ? 
              <div>
                <span className="no-margin">{fr.positionnement.text2}</span> 
                <br /> 
                <span className="text-center" ><EnhancedLink to={ `/${fr.route.position}` } onClick={onClose} className="text-white mgb-0" style={{textDecoration: 'underline'}}> Cliquez ici pour commencer</EnhancedLink> </span> 
              </div>        
              : '' }
          <span style={{position: 'absolute', right: '-20px'}} className="text-white h-text-gray anim-all curp" onClick={ e => onClose() }><svg className="w-3 h-3"><use xlinkHref="#icon-close"/></svg></span>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    me: state.me,
    isLoading: state.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    isConnected: state.auth.isConnected,
  };
};

PositionnementBanner.propTypes = {
  isConnected: PropTypes.bool,
  me: PropTypes.object,
};
  
export default connect(mapStateToProps)(PositionnementBanner);