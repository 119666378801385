import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import isLoading from './isLoading';
import error from './error';
import auth from './auth';
import me from './me';

import countries from './countries';

import selected from './selected';
import toasts from './toasts';

import courses from './courses';
import chapters from './chapters';
import subChapters from './subChapters';
import homeworks from './homeworks';
import questionsGroups from './questionsGroups';
import positioningGroups from './questionsPositioningGroups';
import videos from './videos';
import users from './users';
import faq from './faq';
import agenda from './agenda';
import internships from './internships';
import report from './report';
import ranking from './ranking';
import userTime from './userTime';
import joboffers from './joboffers';
import flipbook from './flipbook';
import dashboard from './dashboard';
import degrees from './degrees';
import badges from './badges';
import challenges from './challenges';
import chat from './chat';
import schoolReport from './schoolReport';
import notifications from './notifications';
import examinations from './examinations';

export default combineReducers({
  router: routerReducer,
  isLoading,
  error,
  auth,
  me,
  countries,
  positioningGroups,
  selected,
  toasts,
  schoolReport,
  courses,
  chapters,
  subChapters,
  homeworks,
  questionsGroups,
  videos,
  flipbook,
  users,
  faq,
  agenda,
  internships,
  report,
  userTime,
  ranking,
  dashboard,
  degrees,
  joboffers,
  badges,
  challenges,
  chat,
  notifications,
  examinations
});
