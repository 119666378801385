import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { NavLink, Link } from 'react-router-dom';

import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';

import { addToGTM } from '../molecules/gtm';

const EnhancedLink = ({ isNavLink, children, ...props }) => (
  isNavLink ? <NavLink { ...props }>{ children }</NavLink> : <Link { ...props }>{ children }</Link>
);

EnhancedLink.propTypes = {
  isNavLink: PropTypes.bool,
  children: PropTypes.any
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      isActive: state.me.selectedOffer &&  state.me.selectedOffer.isActive,
      paymentBanner: state.me.selectedOffer &&  state.me.selectedOffer.paymentBanner
    })
  ),
  mapProps(({ isAuthenticated, isActive, paymentBanner, dispatch, staticContext, track, onClick, ...props }) => ({
    ...props,
    to: typeof props.to === 'string' ?
      { pathname: props.to, isAuthenticated, isActive, paymentBanner } :
      { ...props.to, isAuthenticated, isActive, paymentBanner },
    onClick: track
      ? e => { addToGTM(track); }
      : onClick
  }))
);

export default enhance(EnhancedLink);
