import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_DEGREES_REQUESTED = 'degrees/GET_DEGREES_REQUESTED';
export const GET_DEGREES_SUCCESSFUL = 'degrees/GET_DEGREES_SUCCESSFUL';
export const GET_DEGREES_FAILED = 'degrees/GET_DEGREES_FAILED';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEGREES_SUCCESSFUL:
      return action.degrees;
    case GET_DEGREES_FAILED:
      return initialState;
    case GET_DEGREES_REQUESTED:
      return initialState;
    default:
      return state;
  }
};

export const getDegrees = courseId =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_DEGREES_REQUESTED });

    return Api('/degrees', { params: params })
      .then(res => {
        let degrees = res.data;

        degrees = degrees.map(degree => ({
          ...degree,
          hasBeenDone: false
        }));

        dispatch({
          type: GET_DEGREES_SUCCESSFUL,
          degrees
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_DEGREES_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const setDegrees = degrees =>
  dispatch => {
    return Api.post('/degrees', degrees, { params: params });
  };
