import { compose, withHandlers, withProps } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { markAsRead } from 'stores/notifications';

import Notification from './Notification';

const enhance = compose(
  withRouter,
  connect(
    null,
    dispatch => bindActionCreators({
      markAsRead
    }, dispatch)
  ),
  withProps(({ category }) => {
    let newProps = (function(c) {  
      switch(c) {
        case 2:
          return {
            icon: 'shield',
            color: 'primary'
          };
        case 3:
          return {
            icon: 'lightning',
            color: 'purple'
          };
        case 4:
          return {
            icon: 'podium',
            color: 'teal'
          };
        case 5:
          return {
            icon: 'folder',
            color: 'purple'
          };
        case 6:
          return {
            icon: 'clock-detailed',
            color: 'coral'
          };
        case 7:
          return {
            icon: 'calendar',
            color: 'pink'
          };
        case 8:
          return {
            icon: 'share',
            color: 'turquoise'
          };
        default:
          return {
            icon: 'note-3',
            color: 'gray-light'
          };
      }
    })(category);

    return newProps;
  }),
  withHandlers({
    markAsRead: ({
      markAsRead,
      history,
      id,
      link,
      toggleNotifications
    }) => () => {
      markAsRead(id).then(() => {
        if(link){
          toggleNotifications();
          if(link.indexOf(window.location.hostname) != -1){
            window.location.href = link;
          }else{
            window.open(link, '_blank');
          }
        }
      });
    }
  })
);

export default enhance(Notification);
