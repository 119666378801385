import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const LearningUserRoutes = ({ component: Component, props, ...rest }) => (
  <Route
    {...rest}
    render={p =>       
      p.location.isAuthenticated ? (
        !p.location.isEasySchoolUser && p.location.isActive ? (
          <Component {...p} { ...props } />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: p.location }
            }}
          />
        )
      )
        : <Redirect
          to={{
            pathname: '/',
            state: { from: p.location }
          }}
        />
    }
  />
);

LearningUserRoutes.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object
};

export default LearningUserRoutes;
