import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

import { SET_SELECTED_CHAPTER, RESET_SELECTED_CHAPTER } from './selected';

export const GET_SUBCHAPTERS_REQUESTED = 'subChapters/GET_SUBCHAPTERS_REQUESTED';
export const GET_SUBCHAPTERS_SUCCESSFUL = 'subChapters/GET_SUBCHAPTERS_SUCCESSFUL';
export const GET_SUBCHAPTERS_FAILED = 'subChapters/GET_SUBCHAPTERS_FAILED';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCHAPTERS_SUCCESSFUL:
      return action.subChapters;
    case GET_SUBCHAPTERS_FAILED:
      return initialState;
    case GET_SUBCHAPTERS_REQUESTED:
      return initialState;
    default:
      return state;
  }
};

export const getSubChapters = chapterId =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_SUBCHAPTERS_REQUESTED });
    dispatch({ type: RESET_SELECTED_CHAPTER });

    return Api(`/chapters/${chapterId}`, { params: params })
      .then(res => {
        let { subChapters, ...selectedChapter } = res.data;

        dispatch({
          type: GET_SUBCHAPTERS_SUCCESSFUL,
          subChapters
        });
        dispatch({
          type: SET_SELECTED_CHAPTER,
          selectedChapter
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_SUBCHAPTERS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };