import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

import { SET_SELECTED_COURSE, RESET_SELECTED_COURSE } from './selected';

export const GET_CHAPTERS_REQUESTED = 'chapters/GET_CHAPTERS_REQUESTED';
export const GET_CHAPTERS_SUCCESSFUL = 'chapters/GET_CHAPTERS_SUCCESSFUL';
export const GET_CHAPTERS_FAILED = 'chapters/GET_CHAPTERS_FAILED';
export const SET_CHAPTER_AS_DONE = 'chapters/SET_CHAPTER_AS_DONE';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAPTERS_SUCCESSFUL:
      return action.chapters;
    case GET_CHAPTERS_FAILED:
      return initialState;
    case GET_CHAPTERS_REQUESTED:
      return initialState;
    case SET_CHAPTER_AS_DONE:
      return state.map(chapter => 
        chapter.id !== action.chapterId ?
          chapter :
          { ...chapter, done: true }
      );
    default:
      return state;
  }
};

export const getChapters = courseId =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_CHAPTERS_REQUESTED });
    dispatch({ type: RESET_SELECTED_COURSE });

    return Api(`/courses/${courseId}/chapters`, { params: params })
      .then(res => {
        let { chapters, course: selectedCourse } = res.data;

        chapters = chapters.map(c => ({
          ...c,
          startDate: c.startDate ? c.startDate : null,
          endDate: c.endDate ? c.endDate : null
        }));

        dispatch({
          type: GET_CHAPTERS_SUCCESSFUL,
          chapters
        });
        dispatch({
          type: SET_SELECTED_COURSE,
          selectedCourse
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_CHAPTERS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const setChapterAsDone = chapterId =>
  dispatch => {
    return Api.post('/chapters/done', { id: chapterId }, { params: params })
      .then(res => {
        dispatch({
          type: SET_CHAPTER_AS_DONE,
          chapterId
        });
      });
  };