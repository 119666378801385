import queryString from 'query-string';

import Api, { addAutorization, removeAuthorization, params, addCoaching } from '../modules/Api.js';

import { addToGTM } from '../components/molecules/gtm';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';
import { LOGIN_SUCCESSFUL, LOGIN_FAILED } from './auth';

export const GET_ME_SUCCESSFUL = 'me/GET_ME_SUCCESSFUL';
export const GET_ME_FAILED = 'me/GET_ME_FAILED';

export const UPDATE_PARAMETER_REQUESTED = 'me/UPDATE_PARAMETER_REQUESTED';
export const UPDATE_PARAMETER_SUCCESSFUL = 'me/UPDATE_PARAMETER_SUCCESSFUL';
export const UPDATE_PARAMETER_FAILED = 'me/UPDATE_PARAMETER_FAILED';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ME_SUCCESSFUL:
      return action.user;

    case GET_ME_FAILED:
      return {};

    case UPDATE_PARAMETER_SUCCESSFUL:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          ...action.parameter
        }
      };

    case UPDATE_PARAMETER_FAILED:
      return state;

    default:
      return state;
  }
};

export const check = () => dispatch => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  let coach = queryString.parse(window.location.search)['_coaching'] || localStorage.getItem('coach');
  coach && addCoaching(coach);
  let token = window.location.href.split('/')[4] ? window.location.href.split('/')[4] : localStorage.getItem('token');
  let oldToken = localStorage.getItem('token');

  if (token && !oldToken)
  {
    localStorage.setItem('token', token);
  }
  if (token)
  {  
    window.history.replaceState({}, document.title, '/');
  }

  if (token) {
    getMe(token)
      .then(user => {
        dispatch({
          type: GET_ME_SUCCESSFUL,
          user: user
        });

        dispatch({ type: LOGIN_SUCCESSFUL });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: SET_ERROR,
          value: err.response && err.response.status === 401 ? 'notLoggedIn' : 'error'
        });
        localStorage.removeItem('coach');

        dispatch({ type: GET_ME_FAILED });
        dispatch({ type: LOGIN_FAILED });
        dispatch({ type: ISNT_LOADING });
      });
  } else {
    dispatch({ type: GET_ME_FAILED });
    dispatch({ type: ISNT_LOADING });
  }
};

export const getMe = token =>
  new Promise((resolve, reject) => {
    removeAuthorization();
    addAutorization(token);

    Api('/me', { params: params })
      .then(res => {
        addToGTM({
          loginStatus: 'logged',
          visitorID: res.data.username,
          user_type: res.data.roles.includes('ROLE_PROSPECT')
            ? 'prospect'
            : res.data.roles.includes('ROLE_USER') ? 'eleve' : 'prospect'
          ,
          domain: res.data.selectedOffer.domainName,
          offer: res.data.selectedOffer.offerName,
        });

        resolve(res.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) localStorage.removeItem('token');
        removeAuthorization();

        reject(err);
      });
  });

export const updateParameter = parameter => dispatch => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: UPDATE_PARAMETER_REQUESTED });

  Api.put('/user_parameters', parameter, { params: params })
    .then(res => {
      dispatch({
        type: UPDATE_PARAMETER_SUCCESSFUL,
        parameter: parameter
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(err => {
      dispatch({ type: UPDATE_PARAMETER_FAILED });
      if (err.response.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
