import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { compose, withHandlers, withProps } from 'recompose';

import { removeToast } from 'stores/toasts';

import Toast from './Toast';

const enhance = compose(
  withRouter,
  connect(
    state => ({}),
    dispatch => bindActionCreators({
      removeToast
    }, dispatch)
  ),
  withHandlers({
    closeToast: ({
      type,
      id,
      removeToast
    }) => () =>{
      removeToast(id, type);
    }
  }),
  withProps(({ category }) => {
    let newProps = (function(c) {  
      switch(c) {
        case 2:
          return {
            icon: 'shield',
            color: 'primary'
          };
        case 3:
          return {
            icon: 'lightning',
            color: 'purple'
          };
        case 4:
          return {
            icon: 'podium',
            color: 'teal'
          };
        case 5:
          return {
            icon: 'folder',
            color: 'purple'
          };
        case 6:
          return {
            icon: 'clock-detailed',
            color: 'coral'
          };
        case 7:
          return {
            icon: 'calendar',
            color: 'pink'
          };
        case 8:
          return {
            icon: 'share',
            color: 'turquoise'
          };
        default:
          return {
            icon: 'note-3',
            color: 'gray-light'
          };
      }
    })(category);

    return newProps;
  })
);

export default  enhance(Toast);
