import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading.js';
import { SET_ERROR, RESET_ERROR } from './error.js';

export const GET_CHALLENGES_REQUESTED = 'challenges/GET_CHALLENGES_REQUESTED';
export const GET_CHALLENGES_SUCCESSFUL = 'challenges/GET_CHALLENGES_SUCCESSFUL';
export const GET_CHALLENGES_FAILED = 'challenges/GET_CHALLENGES_FAILED';

export const CREATE_CHALLENGE_REQUESTED = 'challenges/CREATE_CHALLENGE_REQUESTED';
export const CREATE_CHALLENGE_SUCCESSFUL = 'challenges/CREATE_CHALLENGE_SUCCESSFUL';
export const CREATE_CHALLENGE_FAILED = 'challenges/CREATE_CHALLENGE_FAILED';

export const GET_CHALLENGERS_REQUESTED = 'challenges/GET_CHALLENGERS_REQUESTED';
export const GET_CHALLENGERS_SUCCESSFUL = 'challenges/GET_CHALLENGERS_SUCCESSFUL';
export const GET_CHALLENGERS_FAILED = 'challenges/GET_CHALLENGERS_FAILED';

export const RESET_CHALLENGE_REQUESTED = 'challenges/RESET_CHALLENGE_REQUESTED';
export const RESET_CHALLENGE_SUCCESSFUL = 'challenges/RESET_CHALLENGE_SUCCESSFUL';
export const RESET_CHALLENGE_FAILED = 'challenges/RESET_CHALLENGE_FAILED';

export const GET_CHALLENGE_QUESTION_REQUESTED = 'challenges/GET_CHALLENGE_QUESTION_REQUESTED';
export const GET_CHALLENGE_QUESTION_SUCCESSFUL = 'challenges/GET_CHALLENGE_QUESTION_SUCCESSFUL';
export const GET_CHALLENGE_QUESTION_FAILED = 'challenges/GET_CHALLENGE_QUESTION_FAILED';

export const GET_CHALLENGE_SCORING_REQUESTED = 'challenges/GET_CHALLENGE_SCORING_REQUESTED';
export const GET_CHALLENGE_SCORING_SUCCESSFUL = 'challenges/GET_CHALLENGE_SCORING_SUCCESSFUL';
export const GET_CHALLENGE_SCORING_FAILED = 'challenges/GET_CHALLENGE_SCORING_FAILED';

const initialState = {
  challenges: {},
  challengers: [],
  question: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHALLENGES_REQUESTED:
      return {
        ...state
      };
    case GET_CHALLENGES_SUCCESSFUL:
      return {
        ...state,
        challenges: action.challenges,
      };
    case GET_CHALLENGES_FAILED:
      return {
        ...state
      };

    case GET_CHALLENGE_QUESTION_REQUESTED:
      return {
        ...state,
        question: state.question.subChapterName ? {
          subChapterName: state.question.subChapterName,
          scoring: state.question.scoring
        } : {},
        scoring: undefined,
        isLoading: true
      };
    case GET_CHALLENGE_QUESTION_SUCCESSFUL:
      return {
        ...state,
        question: action.question,
        answer: initialState.answer,
        isLoading: false
      };
    case GET_CHALLENGE_QUESTION_FAILED:
      return initialState;

    case GET_CHALLENGERS_REQUESTED:
      return {
        ...state
      };
    case GET_CHALLENGERS_SUCCESSFUL:
      return {
        ...state,
        challengers: action.challengers,
      };
    case GET_CHALLENGERS_FAILED:
      return {
        ...state
      };

    case RESET_CHALLENGE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case RESET_CHALLENGE_SUCCESSFUL:
      return initialState;
    case RESET_CHALLENGE_FAILED:
      return initialState;

    
    case CREATE_CHALLENGE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case CREATE_CHALLENGE_SUCCESSFUL:
      return {
        ...state,
        challengeId: action.challengeId,
        isLoading: false
      };
    case CREATE_CHALLENGE_FAILED:
      return initialState;

    case GET_CHALLENGE_SCORING_SUCCESSFUL:
      return {
        ...state,
        scoring: action.scoring
      };
    case GET_CHALLENGE_SCORING_FAILED:
      return {
        ...state,
        scoring: undefined
      };
    default:
      return state;
  }
};

export const getChallenges = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_CHALLENGES_REQUESTED });

    return Api('/challenge', { params: params })
      .then(res => {
        dispatch({
          type: GET_CHALLENGES_SUCCESSFUL,
          challenges: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_CHALLENGES_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const getChallengeQuestion = (challengeId) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: GET_CHALLENGE_QUESTION_REQUESTED });

    return Api(`/challenge/${challengeId}`, { params: params })
      .then(res => {
        let { data } = res;
        
        dispatch({
          type: GET_CHALLENGE_QUESTION_SUCCESSFUL,
          question: Array.isArray(data) ? {} : data
        });

        return data;
      })
      .catch(err => {
        dispatch({ type: GET_CHALLENGE_QUESTION_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
      });
  };

export const createChallenge = (userId, courseId) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: CREATE_CHALLENGE_REQUESTED });

    return Api.post('/challenge/create', { user_id: userId, course_id: courseId,  params: params }, { params: params })
      .then(res => {
        dispatch({
          type: CREATE_CHALLENGE_SUCCESSFUL,
          challengeId: res.data.challenge_id
        });
        dispatch({ type: ISNT_LOADING });
        return res.data;
      })
      .catch(err => {
        dispatch({ type: CREATE_CHALLENGE_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const getChallengers = (courseId) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_CHALLENGERS_REQUESTED });

    return Api(`/course/${courseId}/challenge/find`, { params: params })
      .then(res => {
        dispatch({
          type: GET_CHALLENGERS_SUCCESSFUL,
          challengers: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_CHALLENGERS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };