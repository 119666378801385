import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_JOB_OFFERS_REQUESTED = 'joboffers/GET_JOB_OFFERS_REQUESTED';
export const GET_JOB_OFFERS_SUCCESSFUL = 'joboffers/GET_JOB_OFFERS_SUCCESSFUL';
export const GET_JOB_OFFERS_FAILED = 'joboffers/GET_JOB_OFFERS_FAILED';

export const CHANGE_CV_REQUESTED = 'joboffers/CHANGE_CV_REQUESTED';
export const CHANGE_CV_SUCCESSFUL = 'joboffers/CHANGE_CV_SUCCESSFUL';
export const CHANGE_CV_FAILED = 'joboffers/CHANGE_CV_FAILED';

export const APPLY_SUCCESSFUL = 'joboffers/APPLY_SUCCESSFUL';
export const APPLY_FAILED = 'joboffers/APPLY_FAILED';

const initialState = {
  guide: '',
  offers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_OFFERS_REQUESTED:
      return  initialState;
    case GET_JOB_OFFERS_SUCCESSFUL:
      return action.joboffers;
    case GET_JOB_OFFERS_FAILED:
      return initialState;
    
    case CHANGE_CV_SUCCESSFUL:
      return {
        ...state,
        CVHasChanged: action.CVHasChanged
      };
    case CHANGE_CV_FAILED:
      return {
        ...state,
        CVHasChanged: action.CVHasChanged
      };
    default:
      return state;
  }
};

export const getJobOffers = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_JOB_OFFERS_REQUESTED });

    return Api('/joboffers', { params: params })
      .then(res => {
        dispatch({
          type: GET_JOB_OFFERS_SUCCESSFUL,
          joboffers: {
            ...res.data
          }
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_JOB_OFFERS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };


export const changeCV = (CV) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: CHANGE_CV_REQUESTED });

    let formData = new FormData();
    formData.append('document', CV);

    return Api.post( '/joboffers/cv', formData, { headers: { 'Content-Type': 'multipart/form-data' }, params: params } )
      .then(() => {
        dispatch({
          type: CHANGE_CV_SUCCESSFUL,
          CVHasChanged: true
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: CHANGE_CV_FAILED,
          CVHasChanged: false
        });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };

export const applyToOffer = (id, message) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: CHANGE_CV_REQUESTED });

    return Api.post( `/joboffers/${id}`, { message }, { params: params } )
      .then(() => {
        dispatch({
          type: APPLY_SUCCESSFUL
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: APPLY_FAILED
        });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };
