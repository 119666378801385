import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_FAQ_REQUESTED = 'faq/GET_FAQ_REQUESTED';
export const GET_FAQ_SUCCESSFUL = 'faq/GET_FAQ_SUCCESSFUL';
export const GET_FAQ_FAILED = 'faq/GET_FAQ_FAILED';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_REQUESTED:
      return  initialState;
    case GET_FAQ_SUCCESSFUL:
      return action.domains;
    case GET_FAQ_FAILED:
      return initialState;
    default:
      return state;
  }
};

export const getFaq = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_FAQ_REQUESTED });

    return Api('/faq', { params: params })
      .then(res => {
        dispatch({
          type: GET_FAQ_SUCCESSFUL,
          domains: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_FAQ_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };