import React from 'react';
import PropTypes from 'prop-types';

import fr from 'lang/fr';

import './index.css';

const Toast = ({
  type,
  link,
  message,
  icon,
  color,
  closeToast,
  clickOnToast,
  history
}) =>
  <div className="toast bg-white shadow bd-radius mgb-1 pgv-0_5 pgl-0_5 pgr-2" onClick={ clickOnToast }>
    { type
      ? <React.Fragment>
        <p className="no-margin mgb-0_5 h3 text-primary-dark">{ fr.toast[type].title }</p>
        <p className="no-margin text-primary-dark">{ fr.toast[type].text }</p>
      </React.Fragment>
      : <div className="flex align-start-start">
        <div className={`pg-0_25 bg-${color} text-white bd-radius mgr-0_5`}>
          <svg className="w-2 h-2"><use xlinkHref={`#icon-${icon}`} /></svg>
        </div>
        <p
          className={ `no-margin fz-13 lh-1_2 text-black ${link ? 'curp' : ''}` }
          onClick={
            e => {
              closeToast();
              if (link){
                if(link.indexOf(window.location.hostname) != -1){
                  window.location.href = link;
                }else{
                  window.open(link, '_blank');
                }
              }
            }
          }
        >
          { message }
        </p>
      </div>
    }
    <span className="toast__close" onClick={ closeToast }>
      <svg className="w-2 h-2"><use xlinkHref="#icon-close" /></svg>
    </span>
  </div>;

Toast.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  color:  PropTypes.string,
  closeToast: PropTypes.func,
  clickOnToast: PropTypes.func,
};

export default Toast;
