/* eslint-disable no-mixed-spaces-and-tabs */
import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const RESET_SENDING = 'examinations/RESET_SENDING';

export const POST_EXAM_SUCCESSFUL = 'examinations/POST_EXAM_SUCCESSFUL';
export const POST_EXAM_FAILED = 'examinations/POST_EXAM_FAILED';

export const REQUEST_CONTACT_SUCCESSFUL = 'examinations/REQUEST_CONTACT_SUCCESSFUL';
export const REQUEST_CONTACT_FAILED = 'examinations/REQUEST_CONTACT_FAILED';

export const GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL =
	'examinations/GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL';
export const GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED = 'examinations/GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED';

const initialState = {
  examSent: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL:
      return {
        ...state,
        examinations: action.examinations
      };
    case GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED:
      return {
        ...state,
        examinations: initialState.examinations
      };
    case POST_EXAM_SUCCESSFUL:
      return {
        ...state,
        examSent: action.examSent
      };
    case POST_EXAM_FAILED:
      return {
        ...state,
        examSent: action.examSent
      };
    case REQUEST_CONTACT_SUCCESSFUL:
      return {
        ...state,
        contactSent: action.contactSent
      };
    case REQUEST_CONTACT_FAILED:
      return {
        ...state,
        contactSent: action.contactSent
      };
    case RESET_SENDING:
      return {
        ...state,
        examSent: undefined,
        contactSent: undefined
      };
    default:
      return state;
  }
};

export const resetSending = () => dispatch => {
  dispatch({ type: RESET_SENDING });
};

export const requestContact = () => dispatch => {
  dispatch({ type: IS_LOADING });

  return Api('/user_domain_offer_examinations/send_email')
    .then(() => {
      dispatch({
        type: REQUEST_CONTACT_SUCCESSFUL,
        contactSent: true
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(err => {
      dispatch({
        type: REQUEST_CONTACT_FAILED,
        contactSent: false
      });

      dispatch({ type: ISNT_LOADING });
    });
};

export const postExam = (taken, year, passed, average, file, comment) => dispatch => {
  dispatch({ type: IS_LOADING });
  let formData = new FormData();
  year && formData.append('year', year);
  typeof passed !== 'undefined' && formData.append('isGraduate', passed);
  average && formData.append('average', average);
  comment && formData.append('comment', comment);
  file && formData.append('registrationCertificate', file);

  return Api.post('/user_domain_offer_examinations', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: params
  })
    .then(() => {
      dispatch({
        type: POST_EXAM_SUCCESSFUL,
        examSent: true
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(err => {
      dispatch({
        type: POST_EXAM_FAILED,
        examSent: false
      });

      dispatch({ type: ISNT_LOADING });
    });
};

export const getUserDomainOfferExaminations = () => dispatch => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });

  return Api('/user_domain_offer_examinations', { params: params })
    .then(res => {
      dispatch({
        type: GET_USER_DOMAIN_OFFER_EXAMINATIONS_SUCCESSFUL,
        examinations: res.data
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(err => {
      dispatch({ type: GET_USER_DOMAIN_OFFER_EXAMINATIONS_FAILED });
      if (err.response.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
