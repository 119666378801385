import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendTime } from '../../../stores/userTime';
import env from '../../../env';

const AuditTimer = ({pathname, children, me, isAuthenticated, sendTime}) => {


  // # => for send actual pathname of user when data send 

  // const [previousUrlObject, setPreviousUrlObject] = useState();

  // useEffect(() => {
  //   setPreviousUrlObject((prevState) => ({
  //     ...prevState,
  //     PreviousUrl: prevState ? prevState.CurrentUrl : '',
  //     CurrentUrl: pathname,
  //   }));
  // }, [window.location.pathname]);

  const [seconds, setSeconds] = useState(0);
  const [secondsTimer, setSecondsTimer] = useState(0);
  const [keepedValue, setkeepValue] = useState(0);
  const [IntervalMethod, setIntervalMethod] = useState();
  const [IntervalMethodToSend, setIntervalMethodToSend] = useState();

  // ==> Timer for user active fn
  const Timer = () => {
    setIntervalMethod(
      setInterval(() => {
        setSeconds((prevState) => (prevState + 1));
      }, 1000)
    );
  };
  // ==> TimerToSend Data fn
  const TimerToSend = () => {
    setIntervalMethodToSend(
      setInterval(() => {
        setSecondsTimer(secondsTimer => secondsTimer + 1);
      }, 1000)
    );
  };

  // ==> resetTimerToSend Data fn
  const resetTimerToSend = () => {
    clearInterval(IntervalMethodToSend);
    setSecondsTimer(false);
  };

  // ==> StopTimer fn
  const stopTimer = () => {
    clearInterval(IntervalMethod);
  };

  // # => CONSOLE LOG TO TRACK USER TIME

  // useEffect(() => {
  //   console.log('User Active Timer : ', seconds);
  // }, [seconds]);

  // useEffect(() => {
  //   console.log('Timer To Send', secondsTimer);
  // }, [secondsTimer]);

  // ==> SendTimeUser method to send time 

  const SendTimeUser = e => {
    if (secondsTimer > 0 && window.location.pathname !== '/login' && seconds > 0 && seconds !== keepedValue)
    {
      let timeObject = {              
        time: seconds, 
        pathName: null,
        // previousUrlObject && previousUrlObject.CurrentUrl ? previousUrlObject.CurrentUrl : '/',
      };
      setkeepValue(seconds);
      sendTime(timeObject, me.selectedOffer.numContract);
      setSeconds(0);
    }
  };

  // ==> First Mount of Timer for Send data
  useEffect(() => {
  }, [isAuthenticated]);

  // ==> Hooks for send data
  useEffect(() => {
  });

  // ==> Relaunch the timer for send data 
  useEffect(() => {
  }, [secondsTimer]);

  // => If user is active
  const onActive = e => {
  };

  const DEFAULT_EVENTS = ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll' ];

  const onIdle = e => {
  };

  return (
    <IdleTimer
      timeout={env.IdleUserTimeout}
      events={DEFAULT_EVENTS}
      onActive={onActive}
      onIdle={onIdle}>
      {children}
    </IdleTimer>
  );
};

AuditTimer.defaultProps = {};
AuditTimer.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
  me: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  sendTime: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({ sendTime }, dispatch);

const mapStateToProps = state => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditTimer);
