import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_INTERNSHIPS_REQUESTED = 'internships/GET_INTERNSHIPS_REQUESTED';
export const GET_INTERNSHIPS_SUCCESSFUL = 'internships/GET_INTERNSHIPS_SUCCESSFUL';
export const GET_INTERNSHIPS_FAILED = 'internships/GET_INTERNSHIPS_FAILED';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INTERNSHIPS_REQUESTED:
      return  initialState;
    case GET_INTERNSHIPS_SUCCESSFUL:
      return action.data;
    case GET_INTERNSHIPS_FAILED:
      return initialState;
    default:
      return state;
  }
};

export const getInternships = () =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_INTERNSHIPS_REQUESTED });

    return Api('/internships', { params: params })
      .then(res => {
        dispatch({
          type: GET_INTERNSHIPS_SUCCESSFUL,
          data: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_INTERNSHIPS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };