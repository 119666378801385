import React from 'react';
import PropTypes from 'prop-types';

const Alertbanner = ({
  me,
  showAlertBanner,
  closeAlertBanner
}) => (
  <div className={ `alertbanner bg-teal-dark pgv-0_75 ${!showAlertBanner && 'hide'}` }>
    <div className="row">
      <div className="column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center">
        <span className="mgl-auto">{me.alert.message}</span>
        <span className="mgl-auto text-white h-text-gray anim-all curp" onClick={ e => closeAlertBanner() }><svg className="w-3 h-3"><use xlinkHref="#icon-close"/></svg></span>
      </div>
    </div>
  </div>
);

Alertbanner.propTypes = {
  me: PropTypes.object,
  closeAlertBanner: PropTypes.func,
  showAlertBanner: PropTypes.bool
};

export default Alertbanner;


