import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const Menu = ({
  me,
  onOpenOfferPopin,
  logout,
  onOfferPopinRef,
  submitOffer,
  selectedOffer,
  shouldRedirect,
  isProspect,
  isFreeAccount,
  onToggleMenuClick,
  openMenu,
  onToggleAccountAccordionClick,
  openAccountAccordion,
  isEasySchoolUser
}) => {

  return shouldRedirect ? (
    <Redirect push={true} to={{ pathname: '/' }} />
  ) : (
    <React.Fragment>
      <div className="mgr-1 header__profile__trigger" onClick={onToggleMenuClick}>
        <div className={`header__profile__trigger__icon w-4 h-4 anim-all ${openMenu && 'open'}`}>
          <span className="icon icon-open">
            <svg className="text-white w-4 h-4">
              <use xlinkHref="#icon-menu" />
            </svg>
          </span>
          <span className="icon icon-close sm-flex lg-dp-none">
            <svg className="text-white w-4 h-4 ">
              <use xlinkHref="#icon-close" />
            </svg>
          </span>
        </div>
      </div>
      <div className={`header__profile__submenu ${openMenu && 'open'}`}>
        <a href="" className="menu--logout" onClick={logout}>
					Se déconnecter
        </a>
      </div>
    </React.Fragment>
  );
};

Menu.propTypes = {
  me: PropTypes.object,
  logout: PropTypes.func,
  onOfferPopinRef: PropTypes.func,
  onOpenOfferPopin: PropTypes.func,
  isEasySchoolUser: PropTypes.any,
  submitOffer: PropTypes.func,
  selectedOffer: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  isProspect: PropTypes.bool,
  isFreeAccount : PropTypes.bool,
  onToggleMenuClick: PropTypes.func,
  openMenu: PropTypes.bool,
  onToggleAccountAccordionClick: PropTypes.func,
  openAccountAccordion: PropTypes.bool
};

export default Menu;
