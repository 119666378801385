import Api, { params } from '../modules/Api.js';
import isDateBefore from 'utils/isDateBefore';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_NOTIFICATIONS_REQUESTED = 'notifications/GET_NOTIFICATIONS_REQUESTED';
export const GET_NOTIFICATIONS_SUCCESSFUL = 'notifications/GET_NOTIFICATIONS_SUCCESSFUL';
export const GET_NOTIFICATIONS_FAILED = 'notifications/GET_NOTIFICATIONS_FAILED';

export const READ_NOTIFICATIONS_REQUESTED = 'notifications/READ_NOTIFICATIONS_REQUESTED';
export const READ_NOTIFICATIONS_SUCCESSFUL = 'notifications/READ_NOTIFICATIONS_SUCCESSFUL';
export const READ_NOTIFICATIONS_FAILED = 'notifications/READ_NOTIFICATIONS_FAILED';

const sortByDate = notifications => notifications.map(n => ({
  ...n,
  date: new Date(n.date)
})).sort((a,b) => isDateBefore(a,b));

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUESTED:
      return  initialState;
    case GET_NOTIFICATIONS_SUCCESSFUL:
      return sortByDate(action.data);
    case READ_NOTIFICATIONS_SUCCESSFUL:
      return state.map(n => ({
        ...n,
        read: action.id === n.id ? !n.read : n.read
      }));
    case GET_NOTIFICATIONS_FAILED:
      return initialState;
    default:
      return state;
  }
};

export const getNotifications = (limit) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_NOTIFICATIONS_REQUESTED });

    return Api(`/notifications${limit ? `/${limit}` : ''}`, { params: params })
      .then(res => {
        dispatch({
          type: GET_NOTIFICATIONS_SUCCESSFUL,
          data: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_NOTIFICATIONS_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };



export const markAsRead = (id) =>
  dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: READ_NOTIFICATIONS_REQUESTED });

    return Api.put( `/notification/${id}/read`, null, { params: params } )
      .then(() => {
        dispatch({
          type: READ_NOTIFICATIONS_SUCCESSFUL,
          id
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({
          type: READ_NOTIFICATIONS_FAILED
        });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };
