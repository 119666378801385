import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_FLIPBOOK_REQUESTED = 'flipbook/GET_FLIPBOOK_REQUESTED';
export const GET_FLIPBOOK_SUCCESSFUL = 'flipbook/GET_FLIPBOOK_SUCCESSFUL';
export const GET_FLIPBOOK_FAILED = 'flipbook/GET_FLIPBOOK_FAILED';

const initialState = '';

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FLIPBOOK_REQUESTED:
      return  initialState;
    case GET_FLIPBOOK_SUCCESSFUL:
      return action.flipbook;
    case GET_FLIPBOOK_FAILED:
      return initialState;
    default:
      return state;
  }
};

export const getFlipbook = (chapterId) =>
  async dispatch => {
    dispatch({ type: RESET_ERROR });
    dispatch({ type: IS_LOADING });
    dispatch({ type: GET_FLIPBOOK_REQUESTED });
    return Api.get(`/flipbook/${chapterId}`, { params: params })
      .then(res => {
        dispatch({
          type: GET_FLIPBOOK_SUCCESSFUL,
          flipbook: res.data
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch(err => {
        dispatch({ type: GET_FLIPBOOK_FAILED });
        if(err.response.status !== 401){
          dispatch({
            type: SET_ERROR,
            value: err
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  };