import PropTypes from 'prop-types';

import gtmParts from 'react-google-tag-manager';

import { compose, lifecycle, mapProps } from 'recompose';

import env from '../../env';

const GoogleTagManager = ({ Script }) => Script;

GoogleTagManager.propTypes = {
  Script: PropTypes.element
};

const enhance = compose(
  mapProps(props => ({
    gtm: gtmParts({
      id: env.GTM.code,
      additionalEvents: { loginStatus: 'unlogged' },
      dataLayerName: 'dataLayer'
    }),
    hasCode: !!env.GTM.code
  })),
  lifecycle({
    componentDidMount(){
      if (!window['dataLayer'] && this.props.hasCode) {
        eval(this.props.gtm.scriptAsHTML().replace(/<\/?script>/g, '')); // eslint-disable-line
      }
    }
  }),
  mapProps(props => ({
    Script: props.hasCode ? props.gtm.noScriptAsReact() : null
  }))
);

export default enhance(GoogleTagManager);

export const addToGTM = data => {
  if(env.debug){ console.log('Adding to GTM dataLayer', data); }
  window['dataLayer'] && window['dataLayer'].push(data);
};